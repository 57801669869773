import { TempBookListsAll } from '../templates/TempBookListsAll';
import { TempBookListsOwn } from '../templates/TempBookListsOwn';
import { GetBooks } from '../../containers/GetBooks';
import React, { useContext } from 'react';
import { switchStateVar } from '../atoms/SwitchState';
import { useReactiveVar } from '@apollo/client';
import { AuthContext } from '../../containers/auth';

// ログイン状態、own状態に応じてコンテンツを切り替え
const BookLists = React.memo(() => {
  const isOwn = useReactiveVar(switchStateVar);
  const { authState } = useContext(AuthContext);
  return (
    <>
      {authState.status === 'in' && isOwn ? (
        <TempBookListsOwn authState={authState} />
      ) : (
        <TempBookListsAll authState={authState} />
      )}
    </>
  );
});

export { BookLists };
