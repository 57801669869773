// postgresSQLから自分の所持した本情報を取得
import { useQuery, gql, NetworkStatus } from '@apollo/client';

// postgresSQLの本情報を取得

const SEARCH_BOOKSANDMEMOS = gql`
  query MyQuery($memo_text: String, $title: String) {
    books(
      where: {
        books_to_memos: { memo_text: { _ilike: $memo_text } }
        title: { _ilike: $title }
      }
    ) {
      isbn
      title
      image_path
    }
  }
`;
// user_nameを引数にselectする
const useSearchBooksAndMemos = (v) => {
  // console.log(v, 'graphQLに来たやつ');
  const { loading, error, data, refetch, networkStatus } = useQuery(
    SEARCH_BOOKSANDMEMOS,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        title: `%${v.bookTitle}%`,
        memo_text: `%${v.memo}%`,
      },
      fetchPolicy: 'no-cache',
    }
  );

  if (networkStatus === NetworkStatus.refetch) refetch();
  if (loading) return null;
  if (error) return `Error! ${error}`;
  return data;
};

export { SEARCH_BOOKSANDMEMOS, useSearchBooksAndMemos };
