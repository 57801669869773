import React from 'react';
import { Avatar, Typography, Grid } from '@mui/material';
import { Skeleton } from '@mui/material';

/**
 *
 * @returns
 */
const AccountProfile = React.memo((v) => {
  // console.log(v);

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      // style={{ marginTop: '-10px' }}
    >
      {!v.userData ? (
        <>
          <Skeleton
            variant="circular"
            style={{ height: '120px', width: '120px' }}
          />
          <Skeleton variant="h6" width={200} />
          <Skeleton variant="text" width={200} />
        </>
      ) : (
        <>
          <Avatar
            src={v.userData.display_image.replace('_normal', '')}
            alt=""
            style={{ height: '120px', width: '120px' }}
          />
          <Typography variant="h5" style={{ fontWeight: 'bold' }}>
            {v.userData.display_name}
          </Typography>
          <Typography variant="subtitle1">@{v.userData.user_name}</Typography>
        </>
      )}
    </Grid>
  );
});

export { AccountProfile };
