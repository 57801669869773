import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';

// もらったpropsをonClickとして実行する
const AddBookIcon = (props) => {
  return (
    <Fab
      color="secondary"
      position="absolute"
      top={40}
      left="40%"
      aria-label="add"
      onClick={props.click}
    >
      <AddIcon />
    </Fab>
  );
};

export { AddBookIcon };
