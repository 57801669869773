import React, { useState } from 'react';
import {
  Typography,
  Box,
  Card,
  CardContent,
  CardActions,
  CardHeader,
  useMediaQuery,
  IconButton,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
// import { Link as RouterLink } from 'react-router-dom';
// import { Login } from '../molecules/Login';
// import { UseGetIdentifier } from '../../containers/auth';
// import { useUpsertUserInfo } from '../../containers/UpsertUser';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    marginTop: '80px',
    justifyContent: 'center',
    display: 'flex',
  },
  card: {
    // width: '90vw',
  },
  title: {
    flexGrow: 1,
    color: '#fff',
    textDecoration: 'none',
  },
}));

const Guide = () => {
  const myStorage = window.localStorage;
  const [showGuide, setShowGuide] = useState(
    myStorage.getItem('isShowGuide') === 'false'
  );
  const classes = useStyles();
  // const mobileStyle = useMediaQuery('(max-width:600px)');
  // const userIdentifier = UseGetIdentifier();
  // userIdentifier.identifier && console.log(userIdentifier, '初期ログイン');
  // const upsertUserIdentifier = useUpsertUserInfo();

  const changeLocalStorage = () => {
    myStorage.setItem('isShowGuide', 'false');
    setShowGuide(!showGuide);
  };

  // useEffect(() => {
  //   // console.log(myStorage.getItem('isShowGuide') === 'false', 'storage');
  //   // console.log(showGuide, 'state');

  //   // setShowGuide(myStorage.getItem('isShowGuide'));
  // }, [showGuide]);

  // userIdentifier.identifier && upsertUserIdentifier(userIdentifier.identifier);
  return (
    <>
      {!showGuide ? (
        <Box className={classes.root} pt={1}>
          <Card variant="outlined">
            <CardHeader
              title="ノートクオリア"
              titleTypographyProps={{
                fontSize: 12,
                mb: -0.5,
                color: 'text.secondary',
              }}
              subheader="NoteQualiaとは？"
              subheaderTypographyProps={{ variant: 'h6', color: 'text.main' }}
              action={
                <IconButton onClick={changeLocalStorage}>
                  <CloseSharpIcon />
                </IconButton>
              }
              style={{ marginBottom: '-20px' }}
            />
            <CardContent className={classes.card}>
              <Typography variant="body2">
                NoteQualiaは読書中のメモを残しておけるサービスです。
              </Typography>
              <Typography variant="body2">
                他のユーザーのメモを見ることで、新しい発見もあるかもしれません。
              </Typography>
              <Typography variant="body2">
                メモに含まれる単語を検索して、本を探すこともできます。
              </Typography>
            </CardContent>
          </Card>
        </Box>
      ) : null}
    </>
  );
};

export { Guide };
