// postgresSQLから本情報を取得
import { useQuery, gql, NetworkStatus } from '@apollo/client';

// postgresSQLの本情報を取得

const GET_BOOKS = gql`
  query getBooks(
    $limit_number: Int
    $offset_number: Int
    $subject_code: String
  ) {
    books(
      limit: $limit_number
      offset: $offset_number
      order_by: { updated_at: desc }
      where: { subject_code: { _like: $subject_code } }
    ) {
      title
      isbn
      image_path
      publish_date
    }
  }
`;

const GET_BOOKS2 = gql`
  query MyQuery($user_id: String, $limit_number: Int, $offset_number: Int) {
    memos(
      distinct_on: isbn
      where: { user_id: { _eq: $user_id } }
      limit: $limit_number
      offset: $offset_number
    ) {
      updated_at
      memos_to_books {
        isbn
        title
        image_path
        publish_date
      }
    }
  }
`;

const GetBooks = (limit, offset, subjectCode) => {
  const { loading, error, data, refetch, networkStatus } = useQuery(GET_BOOKS, {
    notifyOnNetworkStatusChange: true,
    variables: {
      limit_number: limit || 0,
      offset_number: offset || 0,
      subject_code: subjectCode || '%',
    },
    fetchPolicy: 'cache-first',
    // pollInterval: 10000,
  });

  if (networkStatus === NetworkStatus.refetch) refetch();
  if (loading) return null;
  if (error) return `Error! ${error}`;
  return data;
};

export { GET_BOOKS, GET_BOOKS2, GetBooks };
