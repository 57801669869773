import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import { useUpsertUserInfo } from './UpsertUser';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBACKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGESENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
};
firebase.initializeApp(firebaseConfig);

const signIn = () => {
  let provider = new firebase.auth.TwitterAuthProvider();
  // twitterSignInPopup(provider);
  firebase.auth().signInWithRedirect(provider);
  firebase
    .auth()
    .getRedirectResult()
    .then((result) => {
      if (result.credential) {
        /** @type {firebase.auth.OAuthCredential} */
        var credential = result.credential;

        // This gives you a the Twitter OAuth 1.0 Access Token and Secret.
        // You can use these server side with your app's credentials to access the Twitter API.
        var token = credential.accessToken;
        var secret = credential.secret;
        alert(credential);
        // ...
      }

      // The signed-in user info.
      var user = result.user;
    })
    .catch((error) => {});
};

const signOut = () => {
  firebase
    .auth()
    .signOut()
    .then(() => {
      // console.log('Success Signout');
    })
    .catch((error) => {
      console.error(error);
    });
};

const getCurrentUser = () => {
  // const [currentUser, setCurrentUser] = useState('');
  firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      console.log('authChanged');
      console.log(user.photoURL);
      console.log(user);
      return user.displayName;
    } else {
      // setCurrentUser('');
      return null;
    }
  });
  // return <div>{currentUser}</div>;
};

const UseGetCurrentUser = () => {
  const [currentUser, setCurrentUser] = useState('');
  firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      console.log('UseauthChanged');
      console.log(user);
      setCurrentUser(user.displayName);
      // return user.displayName;
    } else {
      setCurrentUser('');
      // return null;
    }
  });
  return { currentUser };
};

const UseGetIdentifier = () => {
  const [identifier, setIdentifier] = useState(null);
  const upsertQuery = useUpsertUserInfo();
  firebase
    .auth()
    .getRedirectResult()
    .then((userCredential) => {
      setIdentifier(
        userCredential.additionalUserInfo && {
          user_id: userCredential.user.uid,
          display_name: userCredential.user.displayName,
          display_image:
            userCredential.additionalUserInfo.profile.profile_image_url,
          user_name: userCredential.additionalUserInfo.username,
        }
      );
      // console.log(userCredential.additionalUserInfo && userCredential.user.uid);
      // console.log(
      //   userCredential.additionalUserInfo && userCredential.user.displayName,
      //   'Authでの確認'
      // );
      if (identifier) {
        // console.log(identifier, 'upsertQueryのログ');
        upsertQuery(identifier);
      }
    });
  return { identifier };
};

export const AuthContext = React.createContext();
export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({ status: 'loading' });

  useEffect(() => {
    // hasura関連を追加
    return firebase.auth().onIdTokenChanged(async (user) => {
      if (user) {
        const token = await user.getIdToken();
        const idTokenResult = await user.getIdTokenResult();
        const hasuraClaim = await idTokenResult.claims[
          'https://hasura.io/jwt/claims'
        ];

        if (!!hasuraClaim) {
          setAuthState({ status: 'in', user, token });
        } else {
          // 初回ログイン時にhasuraClaimがまだない時は、realtimeDBの変更を検知してstateを更新させる
          // console.log('Firestoreを検知');
          const db = firebase.firestore();
          const metadataRef = db.collection('user_meta').doc(user.uid);
          // console.log(metadataRef, 'メタ取得');

          metadataRef.onSnapshot(async () => {
            const token = await user.getIdToken(true);
          });
        }
      } else {
        setAuthState({ status: 'out' });
      }
    });
  }, []);

  useEffect(() => {
    const handle = setInterval(async () => {
      const user = firebase.auth().currentUser;
      if (user) {
        const token = await user.getIdToken(true);
        const idTokenResult = await user.getIdTokenResult();
        const hasuraClaim =
          idTokenResult.claims['https://hasura.io/jwt/claims'];
        if (hasuraClaim) {
          setAuthState({ status: 'in', user, token });
        } else {
          setAuthState({ status: 'out' });
        }
      }
    }, 10 * 60 * 1000);
    return () => clearInterval(handle);
  }, []);

  return (
    <AuthContext.Provider value={{ authState }}>
      {children}
    </AuthContext.Provider>
  );
};

export { signIn, signOut, getCurrentUser, UseGetCurrentUser, UseGetIdentifier };
