import React, { useState, useEffect, useContext } from 'react';
import { BooksNew } from '../organisms/BooksNew';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { AddBookBefore } from '../organisms/AddBook_Before';
import { AddBookBeforeNotLogin } from '../organisms/AddBookBeforeNotLogin';
// import { AuthContext } from '../../containers/auth';
import { GET_BOOKS2 } from '../../containers/GetBooks';
import { SearchBefore } from '../organisms/SearchBefore';
import { HelpIcon } from '../atoms/HelpIcon';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import LoadingCircle from '../atoms/LoadingCircle';
import { switchStateVar } from '../atoms/SwitchState';
import { useReactiveVar } from '@apollo/client';

const useStyles = makeStyles(() => ({
  content: {
    margin: '0px 0 0 0',
    position: 'static',
  },
}));

const TempBookListsOwn = React.memo((v) => {
  const [bookData, setBookData] = useState([]);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const classes = useStyles();
  const authState = v.authState;
  const isOwn = useReactiveVar(switchStateVar);

  const { error, fetchMore } = useQuery(GET_BOOKS2, {
    variables: {
      subject_code: '%',
      limit_number: 12,
      user_id: authState && authState.user.uid,
    },
    // onCompleted: (data) => {
    //   if (!data.memos.length) {
    //     const { books } = data.memos;
    //     setBookData(books);
    //   }
    // },
  });

  const getBooksData = async () => {
    setOffset((prevOffset) => prevOffset + 12);
    const { data } = await fetchMore({
      variables: { limit_number: 12, offset_number: offset },
    });
    // 取得したdata件数が0の時はhasMoreをfalseにして、スクロールを終了
    data.memos.length === 0 && setHasMore(!hasMore);
    // if ((bookData.length = 0)) {
    //   setBookData((prev) => [...data.memos]);
    // } else {
    setBookData((prev) => [...prev, ...data.memos]);
    // }
  };

  useEffect(() => {
    getBooksData();
    // console.log(authState && authState.user.uid, 'uidの確認');
  }, [isOwn]);

  // ---------------

  // 子コンポーネントに渡すためにbookDataを整形する

  let resData =
    bookData &&
    // bookData.memos_to_books &&
    bookData.reduce((res, cur) => {
      const element = res.find((v) => v.isbn === cur.memos_to_books.isbn);
      if (element) {
      } else {
        res.push({
          isbn: cur.memos_to_books.isbn,
          title: cur.memos_to_books.title,
          image_path: cur.memos_to_books.image_path,
        });
      }
      return res;
    }, []);

  return (
    <Box className={classes.content}>
      {!bookData && <LoadingCircle />}
      {resData && resData.length > 0 && (
        <BooksNew
          data={resData}
          nextFunc={() => getBooksData()}
          hasMore={hasMore}
        />
      )}

      <Box position="fixed" bottom="26%" right="4%">
        <Link to={`about`}>
          <HelpIcon />
        </Link>
      </Box>

      <Box position="fixed" bottom="15%" right="4%">
        <SearchBefore />
      </Box>

      <Box position="fixed" bottom="4%" right="4%">
        {authState.status === 'in' ? (
          <AddBookBefore />
        ) : (
          <AddBookBeforeNotLogin />
        )}
      </Box>
    </Box>
  );
});

export { TempBookListsOwn };
