import React, { useEffect, useMemo, useContext } from 'react';
import styled from 'styled-components';
import { AccountProfile } from '../organisms/AccountProfile';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetOwnUserAndBooks } from '../../containers/GetOwnUserAndBooks';
import { Grid, Typography } from '@mui/material';
// import { Skeleton } from '@mui/material';
// import { AuthContext } from '../../containers/auth';
import { BooksNew } from '../organisms/BooksNew';
import { Helmet } from 'react-helmet';
import { useTracking } from '../../containers/useTracking';

const Wrapper = styled.div`
  margin: 10px auto 0;
`;

/** After Logged in, check own uuid in the user table.
When returning is Array(0), insert firebase user Info.
When returning is Array(1), do nothing.
 *
*/
const Account = React.memo(() => {
  let { slug } = useParams();
  useTracking(process.env.REACT_APP_GA_MEASUREMENT_ID);
  const booksAndMemos = useGetOwnUserAndBooks(slug);

  // console.log(slug, 'slugテスト');
  // console.log(booksAndMemos && booksAndMemos.users, 'メモとか');

  // userが存在しない場合はtopに遷移
  const navigate = useNavigate();
  booksAndMemos && booksAndMemos.users.length === 0 && navigate('../');

  const countMemos =
    booksAndMemos && booksAndMemos.memos_aggregate.aggregate.count;
  const countBooks =
    booksAndMemos && booksAndMemos.books_aggregate.aggregate.count;

  // console.log(countMemos, 'memo');
  // console.log(countBooks, 'book');

  // 初回レンダリング時にページトップにスクロールさせる
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Wrapper>
      <Helmet>
        <title>Account</title>
      </Helmet>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        {
          <AccountProfile
            userData={
              booksAndMemos && booksAndMemos.users && booksAndMemos.users[0]
            }
          />
        }
        <Grid container>
          <Grid item xs={4} />
          <Grid item xs={2} textAlign="center">
            <Typography variant="body1" style={{ fontWeight: 'bold' }}>
              Notes
            </Typography>
            {countMemos}
          </Grid>
          <Grid item xs={2} textAlign="center">
            <Typography variant="body1" style={{ fontWeight: 'bold' }}>
              Books
            </Typography>
            <Typography>{countBooks}</Typography>
          </Grid>
          <Grid item xs={4} />
        </Grid>
        {booksAndMemos && <BooksNew data={booksAndMemos.books} />}
      </Grid>
    </Wrapper>
  );
});

export { Account };
