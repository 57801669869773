import { TempNotesListsAll } from '../templates/TempNotesListsAll';
import { TempNotesListsOwn } from '../templates/TempNotesListsOwn';
import React, { useContext } from 'react';
import { switchStateVar } from '../atoms/SwitchState';
import { useReactiveVar } from '@apollo/client';
import { AuthContext } from '../../containers/auth';

const NoteLists = React.memo(() => {
  // console.log('NoteListsのレンダー');
  const isOwn = useReactiveVar(switchStateVar);
  const { authState } = useContext(AuthContext);
  return (
    <>
      {authState.status === 'in' && isOwn ? (
        <TempNotesListsOwn />
      ) : (
        <TempNotesListsAll />
      )}
    </>
  );
});

export { NoteLists };
