import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Grid, Typography } from '@mui/material';
import { Helmet } from 'react-helmet';
import { useTracking } from '../../containers/useTracking';
import { Link } from 'react-router-dom';

const Wrapper = styled.div`
  margin: 20px 20px 0;
  // width: 100%;
  justify-content: center;
  display: flex;
  text-align:center;
  // flex: wrap;
  maxWidth="700px"

  // maxwidth: 600px;
`;

// 初回レンダリング時にページトップにスクロールさせる

const About = React.memo(() => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useTracking(process.env.REACT_APP_GA_MEASUREMENT_ID);
  return (
    <Wrapper>
      <Helmet>
        <title>about</title>
      </Helmet>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        // width="100%"
      >
        <Typography
          variant="h4"
          gutterBottom
          style={{ fontWeight: 600, marginTop: 0 }}
        >
          NoteQualiaとは
        </Typography>
        <br />
        <Typography paragraph>
          読書後の感想やレビューよりも、読書中に気になった文言や思いつきを忘れないように書き残したい人へ。
        </Typography>
        <Typography paragraph>
          NoteQualiaは読書中にメモを残せる場所です。
        </Typography>
        <Typography paragraph>
          他のユーザーが残したメモも見ることができて、新しい発見もあるかもしれません。
        </Typography>
        <Typography paragraph>
          メモに含まれる単語を検索して、本を探すこともできます。
        </Typography>
        <br />
        <Typography paragraph style={{ fontWeight: 600 }}>
          自分でも思いも寄らない考えや本と出会う場になれば。
        </Typography>
        <Link to={'/tos'} style={{ textDecoration: 'none' }}>
          <Typography
            variant="h6"
            gutterBottom
            underline="none"
            style={{
              fontWeight: 600,
              marginTop: 40,
              textDecoration: 'none',
              color: '#000',
            }}
          >
            利用規約
          </Typography>
        </Link>
        <Link to={'/policy'} style={{ textDecoration: 'none' }}>
          <Typography
            variant="h6"
            gutterBottom
            style={{ fontWeight: 600, marginTop: 10, color: '#000' }}
          >
            プライバシーポリシー
          </Typography>
        </Link>
        <Typography
          variant="h6"
          gutterBottom
          style={{ fontWeight: 600, marginTop: 10 }}
        >
          <a
            style={{ textDecoration: 'none', color: '#000' }}
            href="https://docs.google.com/forms/d/e/1FAIpQLSfwkBUXUoZN_3eP8yrPR9dp2-2gYZBAPXw0THXP1hwi46TggA/viewform"
            target="_blank"
            rel="noreferrer"
          >
            お問い合わせ
          </a>
        </Typography>
      </Grid>
    </Wrapper>
  );
});

export { About };
