// postgresSQLから本情報を取得
import { useQuery, gql, NetworkStatus } from '@apollo/client';

// postgresSQLの本情報を取得

const GET_GRAPH_DATA = gql`
  query MyQuery($isbn: String, $user_id: String, $display_name: String) {
    memos(
      where: {
        isbn: { _eq: $isbn }
        user_id: { _eq: $user_id }
        memos_to_users: { display_name: { _eq: $display_name } }
      }
      order_by: { created_at: desc }
    ) {
      memo_id
      memo_title
      memo_text
      isbn
      user_id
      created_at
      memos_to_books {
        title
      }
      memos_to_users {
        display_name
        user_name
      }
    }
  }
`;

const useGetGraphData = (user_id) => {
  const { loading, error, data } = useQuery(GET_GRAPH_DATA, {
    variables: { user_id: user_id },
    // fetchPolicy: 'no-cache',
    // pollInterval: 5000
  });

  if (loading) return null;
  if (error) return `Error! ${error}`;
  return data;
};

export { GET_GRAPH_DATA, useGetGraphData };
