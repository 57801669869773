import { gql, useMutation } from '@apollo/client';
import { GET_MEMOS } from './GetMemos';

/** openDBから取得したデータをpostgresSQLに登録
 *
 */
const UPDATE_MEMO = gql`
  mutation MyMutation(
    $memo_id: String
    $user_id: String
    $isbn: String
    $memo_title: String
    $memo_text: String
  ) {
    insert_memos(
      objects: {
        memo_id: $memo_id
        user_id: $user_id
        isbn: $isbn
        memo_title: $memo_title
        memo_text: $memo_text
      }
      on_conflict: {
        constraint: memos_pkey
        update_columns: [memo_title, memo_text, updated_at]
      }
    ) {
      affected_rows
      returning {
        memo_id
        memo_title
        memo_text
        user_id
        isbn
      }
    }
  }
`;

const ADD_MEMO = gql`
  mutation MyMutation(
    $memo_id: String
    $user_id: String
    $isbn: String
    $memo_title: String
    $memo_text: String
  ) {
    insert_memos(
      objects: {
        user_id: $user_id
        isbn: $isbn
        memo_title: $memo_title
        memo_text: $memo_text
      }
      on_conflict: {
        constraint: memos_pkey
        update_columns: [memo_title, memo_text, updated_at]
      }
    ) {
      affected_rows
      returning {
        memo_id
        memo_title
        memo_text
        user_id
        isbn
      }
    }
  }
`;

const REMOVE_MEMO = gql`
  mutation removeTodo($memo_id: String) {
    delete_memos(where: { memo_id: { _eq: $memo_id } }) {
      affected_rows
    }
  }
`;

const useAddMemo = () => {
  const [addMemo, { data, error }] = useMutation(UPDATE_MEMO, {
    refetchQueries: [{ query: GET_MEMOS }],
    notifyOnNetworkStatusChange: true,
    // fetchPolicy: 'no-cache',
  });

  const addMemoData = (v) => {
    addMemo({
      variables: {
        memo_id: v.memo_id,
        user_id: v.user_id,
        isbn: v.isbn,
        memo_title: v.memo_title,
        memo_text: v.memo_text,
      },
    });
  };
  return { addMemoData, data, error };
};

export { UPDATE_MEMO, ADD_MEMO, REMOVE_MEMO, useAddMemo };
