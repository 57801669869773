import { gql, useMutation } from '@apollo/client';
import { GET_OWN_USER } from './GetOwnUser';

/** Upsert uid, dispaly_name, display_image from firebase additionUserInfo. If the uid is already existed, the changes are ignored.
 *
 */
const UPSERT_USER = gql`
  mutation MyMutation(
    $user_id: String
    $display_name: String
    $display_image: String
    $user_name: String
  ) {
    insert_users_one(
      object: {
        user_id: $user_id
        display_name: $display_name
        display_image: $display_image
        user_name: $user_name
      }
      on_conflict: { constraint: users_id_key, update_columns: [] }
    ) {
      user_id
      display_name
      display_image
      user_name
    }
  }
`;

/** Insert firebase user Info
 * @param {string} v - firebase user info
 */
const useUpsertUserInfo = (v) => {
  const [upsertUser] = useMutation(UPSERT_USER);
  const upsertUserInfo = (v) => {
    upsertUser({
      variables: {
        user_id: v.user_id,
        display_name: v.display_name,
        display_image: v.display_image.replace('http:', 'https:'),
        user_name: v.user_name,
      },
      refetchQueries: [
        { query: GET_OWN_USER, variables: { user_id: v.user_id } },
      ],
    });
  };
  return upsertUserInfo;
};

export { UPSERT_USER, useUpsertUserInfo };
