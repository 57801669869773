// Loginしていないユーザーに見せるボタン、押すとログインするかを問う

import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { AddBookIcon } from '../atoms/AddIcon';
import { signIn } from '../../containers/auth';

const useStyles = makeStyles({
  contents: {
    textAlign: 'center',
  },
  border: {
    border: 'solid 1px',
  },
});

const AddBookBeforeNotLogin = React.memo(() => {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const loginClose = () => {
    setOpen(false);
    signIn();
  };
  return (
    <>
      <AddBookIcon click={handleClickOpen} />
      <Dialog
        open={open}
        onClose={handleClose}
        // TransitionComponent={Transition}
      >
        {/* <DialogTitle></DialogTitle> */}
        <DialogContent>
          <Typography>
            Twitterでログインすると本の登録・メモができます。
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button variant="contained" onClick={loginClose} color="primary">
            Twitterログイン
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});

export { AddBookBeforeNotLogin };
