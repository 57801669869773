import React, { useState, useEffect, useContext } from 'react';
import { Notes } from '../organisms/Notes';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { AddBookBefore } from '../organisms/AddBook_Before';
import { AddBookBeforeNotLogin } from '../organisms/AddBookBeforeNotLogin';
import { AuthContext } from '../../containers/auth';
import { GET_BOOKS } from '../../containers/GetBooks';
import { GET_MEMOS } from '../../containers/GetMemos';
import { BooksCaption } from '../atoms/BooksCaption';
import { SearchBefore } from '../organisms/SearchBefore';
import { HelpIcon } from '../atoms/HelpIcon';
// import Loading from '../atoms/Loading';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
// import { motion } from 'framer-motion';
import LodingCircle from '../atoms/LoadingCircle';

const moveParent = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.5,
      staggerChildren: 0.05,
      ease: [0.17, 0.67, 0.83, 0.67],
    },
  },
};

const moveItem = {
  hidden: { y: 20, opacity: 0 },
  rest: { scale: 1 },
  hover: { scale: 1.1 },
  pressed: { scale: 0.95 },
  visible: {
    y: 0,
    opacity: 1,
    // transition: {
    //   y: { stiffness: 1000, velocity: -100 },
    // },
  },
};

const useStyles = makeStyles(() => ({
  content: {
    margin: '0px 0 0 0',
    position: 'static',
  },
}));

const TempNotesListsAll = React.memo(() => {
  const [noteData, setNoteData] = useState([]);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const classes = useStyles();
  const { authState } = useContext(AuthContext);

  const { error, fetchMore } = useQuery(GET_MEMOS, {
    variables: {
      limit_number: 12,
    },
    onCompleted: (data) => {
      if (!data.memos.length) {
        const { memos } = data.memos;
        // console.log(data.memos.length, 'onCompletedの確認');
        setNoteData(memos);
      }
      // console.log(data);
    },
  });

  const getNotesData = async () => {
    setOffset((prevOffset) => prevOffset + 12);
    const { data } = await fetchMore({
      variables: { limit_number: 12, offset_number: offset },
    });
    // 取得したdata件数が0の時はhasMoreをfalseにして、スクロールを終了
    // console.log(data);
    data.memos.length === 0 && setHasMore(!hasMore);
    setNoteData((prev) => [...prev, ...data.memos]);
  };

  useEffect(() => {
    getNotesData();
  }, []);

  return (
    <Box className={classes.content}>
      {/* <BooksCaption props={'　'} /> */}
      {noteData && noteData.length <= 0 && <LodingCircle />}
      {/* {noteData && noteData.length > 0 && <BooksSkeleton />} */}
      {noteData && noteData.length > 0 && (
        <Notes
          data={noteData}
          nextFunc={() => getNotesData()}
          hasMore={hasMore}
        />
      )}
      {/* <motion.ul
        variants={moveParent}
        initial="hidden"
        animate="visible"
        style={{ margin: 0, padding: 0 }}
      > */}
      {/* <motion.li variants={moveItem}> */}
      <Box position="fixed" bottom="26%" right="4%">
        <Link to={`about`}>
          <HelpIcon />
        </Link>
      </Box>
      {/* </motion.li> */}
      {/* <motion.li variants={moveItem}> */}
      <Box position="fixed" bottom="15%" right="4%">
        <SearchBefore />
      </Box>
      {/* </motion.li> */}
      {/* <motion.li variants={moveItem}> */}
      <Box position="fixed" bottom="4%" right="4%">
        {authState.status === 'in' ? (
          <AddBookBefore />
        ) : (
          <AddBookBeforeNotLogin />
        )}
      </Box>
      {/* </motion.li> */}
      {/* </motion.ul> */}
    </Box>
  );
});

export { TempNotesListsAll };
