// postgresSQLから自分の所持した本情報を取得
import { useQuery, gql, NetworkStatus } from '@apollo/client';

// postgresSQLの本情報を取得

const GET_OWN_USER_AND_BOOKS = gql`
  query GetOwnUserAndBooks($user_id: String) {
    users(where: { user_id: { _eq: $user_id } }) {
      display_image
      display_name
      text
      user_name
    }
    users(where: { user_id: { _eq: $user_id } }) {
      users_to_owner {
        owner_to_books {
          title
          isbn
          author
          image_path
          pages
          price
          publish_date
          text
        }
      }
    }
  }
`;

const GET_OWN_USER_AND_BOOKS2 = gql`
  query MyQuery($user_name: String) {
    users(where: { user_name: { _eq: $user_name } }) {
      display_image
      display_name
      text
      user_name
    }
    memos_aggregate(
      where: { memos_to_users: { user_name: { _eq: $user_name } } }
    ) {
      aggregate {
        count(columns: memo_id)
      }
    }
    books_aggregate(
      where: {
        books_to_memos: { memos_to_users: { user_name: { _eq: $user_name } } }
      }
    ) {
      aggregate {
        count(columns: isbn)
      }
    }
    books(
      where: {
        books_to_memos: { memos_to_users: { user_name: { _eq: $user_name } } }
      }
    ) {
      title
      isbn
      image_path
    }
  }
`;
// user_nameを引数にselectする
const useGetOwnUserAndBooks = (v) => {
  const { loading, error, data, refetch, networkStatus } = useQuery(
    GET_OWN_USER_AND_BOOKS2,
    {
      notifyOnNetworkStatusChange: true,
      variables: { user_name: v },
    }
  );

  if (networkStatus === NetworkStatus.refetch) refetch();
  if (loading) return null;
  if (error) return `Error! ${error}`;
  return data;
};

export { GET_OWN_USER_AND_BOOKS, useGetOwnUserAndBooks };
