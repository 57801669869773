import { gql, useMutation } from '@apollo/client';
import { GET_BOOKS } from '../containers/GetBooks';

/** openDBから取得したデータをpostgresSQLに登録
 *
 */
const ADD_BOOK = gql`
  mutation MyMutation(
    $title: String
    $price: Int
    $image_path: String
    $subject_code: String
    $pages: Int
    $isbn: String
    $author: String
    $publish_date: String
    $text: String
    $owner_id: String
    $publisher: String
  ) {
    insert_books_one(
      object: {
        title: $title
        price: $price
        isbn: $isbn
        image_path: $image_path
        subject_code: $subject_code
        pages: $pages
        author: $author
        publish_date: $publish_date
        text: $text
        publisher: $publisher
      }
      on_conflict: { constraint: books_pkey, update_columns: [] }
    ) {
      title
    }
  }
`;

const useAddBookData = () => {
  const [addBook] = useMutation(ADD_BOOK, {
    refetchQueries: [
      { query: GET_BOOKS, variables: { limit_number: 5, subject_code: '%' } },
    ],
  });

  const addBookData = (v) => {
    addBook({
      variables: {
        title: v.title,
        price: v.price,
        image_path: v.image_path,
        subject_code: v.subject_code,
        pages: v.pages,
        isbn: v.isbn,
        author: v.author,
        publish_date: v.publish_date,
        text: v.text,
        publisher: v.publisher,
      },
    });
  };
  return addBookData;
};
export { ADD_BOOK, useAddBookData };
