// ownerテーブルに特定のuidとisbnがないか確認するクエリ
// 本を登録する前にすでに登録済みでないかを確認する

import { useQuery, gql, NetworkStatus } from '@apollo/client';

const CHECK_OWNER = gql`
  query checkOwner($user_id: String!, $isbn: String) {
    owner(where: { user_id: { _eq: $user_id }, isbn: { _eq: $isbn } }) {
      user_id
      isbn
    }
  }
`;

/**
 * Check existence of uuid and isbn in the owner table
 * @param {string} v uuid
 * @returns {data} null, error, data
 */
const useCheckOwner = (uid, isbn) => {
  const { loading, error, data, refetch, networkStatus } = useQuery(
    CHECK_OWNER,
    {
      notifyOnNetworkStatusChange: true,
      variables: { user_id: uid, isbn: isbn },
      fetchPolicy: 'no-cache',
    }
  );

  if (networkStatus === NetworkStatus.refetch) refetch();
  if (loading) return 'loading';
  if (error) return `Error! ${error}`;
  return data;
};

export { CHECK_OWNER, useCheckOwner };
