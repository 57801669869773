import React, { useState } from 'react';
import {
  TextField,
  Grid,
  IconButton,
  Box,
  InputAdornment,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import HighlightOffSharpIcon from '@mui/icons-material/HighlightOffSharp';
import { getInfo } from '../../containers/getDataFromOpendb';
import { AddBookDetailModal } from './AddBook_DetailModal';
import { useAddBookData } from '../../containers/AddBook';
import { useCheckBooks } from '../../containers/CheckBooks';
import { useAddOwner } from '../../containers/AddOwner';
import { useCheckOwner } from '../../containers/CheckOwner';
import { useModalBefore } from '../../containers/ModalBeforeRegister';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  // margin: {
  //   margin: .spacing(1),
  // },
  input: {
    width: '190px',
    // border: '1px solid #000',
  },
}));

// propsは親のモーダル(AddBook_Before)を閉じる関数
const InputIsbnFiled = React.memo((props) => {
  // console.log('inputFieldの呼び出し');

  const classes = useStyles();
  // 入力値の保持
  const [code, setCode] = useState('');
  // openDBからのデータ格納
  const [bookData, setBookData] = useState(null);

  // 子のモーダルの開閉と認証情報を保持
  const { open, handleClickOpen, handleClose, ownerId } = useModalBefore();

  // 本を登録する関数
  const addBookData = useAddBookData();

  // 本を所持済みかのチェック、子のコンポーネントでの表示切り替えに使用
  const [bookExist, setBookExist] = useState(false);
  const ownerArr = useCheckOwner(ownerId, code);

  // オーナーテーブルを追加する関数
  const addOwner = useAddOwner();

  // graphQLで取得した配列を格納、handleSubmit内でlengthに応じて取得を分岐
  let gqlRes = useCheckBooks(code);

  const navigate = useNavigate();

  /** Hasuraのbookテーブルからデータ取得した際にstateを更新する関数
   *
   */
  const stateChange = () => {
    setBookData(gqlRes.books[0]);
  };

  // 子のモーダルを閉じる & graphQLを投げる & 親のモーダル(AddBook_Before)を閉じる関数
  // ※本コンポーネント(InputIsbnFiledは親モーダルの一部)
  const handleCloseAndRegist = (v) => {
    handleClose();
    // addBookData(ownerId, v);
    addOwner(ownerId, v);
    setTimeout(() => {
      props && props.onclose();
    }, 300);
    navigate(`../../book/${v.isbn}`);
  };

  const handleChange = (e) => {
    const res = e.target.value.replace(/\D/g, '');
    setCode(res.substring(0, 13));
  };

  /** ISBNコード入力後にHasuraにアクセスし、データがなければopenDBから取得
   *
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(gqlRes, 'GraphQLでの取得結果');
    // lengthが0の時(postgreSQLにデータが存在しない時)はopenDBにアクセスして取得する
    // openDBから取得時はbookテーブルに本情報を登録する
    // console.log(ownerArr, 'オーナーテーブルの確認');
    // console.log(ownerArr.owner.length, 'オーナーテーブルの確認');
    // ownerテーブルを確認して所持済みの本かをチェック
    ownerArr.owner.length === 1
      ? setBookExist(!bookExist)
      : console.log('新規登録');

    // gqlRes.books.lengthでデータがbooksテーブル内にあるかチェックする
    // 0ならなし、1ならあり
    if (gqlRes.books.length === 0) {
      let opendbRes = '';
      opendbRes = await getInfo(code);
      if (opendbRes === 'failure') {
        alert('次のISBNコードが見つかりませんでした:', code);
      } else {
        setBookData(opendbRes);
        addBookData(opendbRes);
        handleClickOpen();
        console.log('openDB経由');
      }
    } else {
      // lengthが0以外(postgreSQLにデータが存在する時)はHasuraにアクセスして取得する
      await stateChange();
      console.log('GraphQL経由');
      bookData && console.log(bookData);
      handleClickOpen();
    }
  };
  const allClear = () => {
    setCode('');
  };

  return (
    <>
      {bookData && bookData !== '' ? (
        <AddBookDetailModal
          bookData={bookData}
          open={open}
          onclose={handleClose}
          bookExist={bookExist}
          // regist関数は引数付きで渡してるのでアロー関数で渡している、子のpropのデータがvに入り実行される
          regist={(v) => handleCloseAndRegist(v)}
        />
      ) : null}
      <Box component="form" onSubmit={handleSubmit} className={classes.margin}>
        {/* <Grid container spacing={0} alignItems="flex-end"> */}
        {/* <Grid item> */}
        <TextField
          id="formatted-numberformat-input"
          name="numberformat"
          variant="standard"
          InputProps={{
            value: code,
            onChange: handleChange,
            endAdornment: (
              <InputAdornment position="end">
                {code && (
                  <IconButton onClick={allClear} size="large">
                    <HighlightOffSharpIcon />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
          label="ISBNコードを入力"
          required
          className={classes.input}
        />
        {/* </Grid> */}
        {/* </Grid> */}
      </Box>
    </>
  );
});

export { InputIsbnFiled };
