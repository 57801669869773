// postgresSQLから本情報を取得
import { useQuery, gql, NetworkStatus } from '@apollo/client';

// postgresSQLの本情報を取得

const GET_BOOKS_ISBN = gql`
  query getBooksWithIsbn($limit_number: Int, $isbn_code: String) {
    books(
      limit: $limit_number
      order_by: { updated_at: desc }
      where: { isbn: { _eq: $isbn_code } }
    ) {
      title
      isbn
      author
      image_path
      pages
      price
      publish_date
      publisher
      text
      amazon_link
      books_to_owner {
        owner_to_users {
          user_name
          display_image
          user_id
        }
        having_status
      }
    }
  }
`;

const GetBooksWithIsbn = (isbn) => {
  const { loading, error, data, refetch, networkStatus } = useQuery(
    GET_BOOKS_ISBN,
    {
      notifyOnNetworkStatusChange: true,
      variables: { limit_number: 1, isbn_code: isbn || '%' },
      fetchPolicy: 'no-cache',
    }
  );

  if (networkStatus === NetworkStatus.refetch) refetch();
  if (loading) return null;
  if (error) return `Error! ${error}`;
  return data;
};

export { GET_BOOKS_ISBN, GetBooksWithIsbn };
