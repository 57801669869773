// ログインしているユーザーが操作できる本を追加するボタン
import React, { useState, useCallback, useMemo } from 'react';
import {
  Dialog,
  Box,
  DialogContent,
  DialogActions,
  DialogContentText,
  DialogTitle,
  Slide,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { InputIsbnFiled } from '../molecules/AddBook_InputIsbnFiled';
import { AddBookIcon } from '../atoms/AddIcon';
import { AddBookScanner } from '../molecules/AddBook_Scanner';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  contents: {
    textAlign: 'center',
  },
  border: {
    border: 'solid 1px',
  },
  space: {
    margin: '10px',
  },
});

const useAddBookBefore = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  // console.log(GetBooksInfo('9784047358522'));
  return {
    classes,
    open,
    handleClickOpen,
    handleClose,
  };
};

const AddBookBefore = React.memo(() => {
  // console.log('addBookBeforeの呼び出し');
  const { classes, open, handleClickOpen, handleClose } = useAddBookBefore();
  return (
    <div>
      <AddBookIcon click={handleClickOpen} />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>メモする本を追加</DialogTitle>
        <DialogContent>
          <DialogActions>
            {/* inputに自身のモーダルを閉じる関数を渡す */}
            <AddBookScanner onclose={handleClose} />
          </DialogActions>
          <DialogActions style={{ justifyContent: 'center' }}>
            {/* inputに自身のモーダルを閉じる関数を渡す */}
            <InputIsbnFiled
              onclose={handleClose}
              style={{ border: '1px solid #000' }}
            />
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
});

export { AddBookBefore };
