// 動的ルーティングでurl(ISBN)を取得して、クエリを発行し、返ってきた情報を表示する
import React, { useEffect } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import {
  Typography,
  Box,
  Grid,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  useMediaQuery,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Skeleton } from '@mui/material';
import { GetBooksWithIsbn } from '../../containers/GetBooksWithIsbn';
import { BookDetailNote } from './BookDetailNote';
import { useTracking } from '../../containers/useTracking';
import { Helmet } from 'react-helmet';

const BookDetail = React.memo((v) => {
  let { slug } = useParams();
  const { hash } = useLocation();
  useTracking(process.env.REACT_APP_GA_MEASUREMENT_ID);
  const mobileStyle = useMediaQuery('(max-width:600px)');

  const useStyles = makeStyles((theme) => ({
    centerContent: {
      display: 'flex',
      justifyContent: 'center',
    },
    cardMedia: {
      height: mobileStyle ? '200px' : '290px',
      width: mobileStyle ? '180px' : '200px',
      objectFit: 'cover',
      backgroundColor: '#000',
    },
  }));

  useEffect(() => {
    hash || window.scrollTo(0, 0);
  }, [hash]);

  const classes = useStyles();
  const data = GetBooksWithIsbn(slug) || '';
  // console.log(data && data);
  const {
    title,
    isbn,
    author,
    image_path,
    pages,
    price,
    publish_date,
    publisher,
    text,
    amazon_link,
  } = data && data.books && data.books[0];
  // const owners = data && data.books[0].books_to_owner;

  const amazonClick = (e) => {
    e.preventDefault();
    window.open(amazon_link);
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta property="og:image" content={image_path} />
      </Helmet>
      <Box className={classes.centerContent} minWidth="360px" padding="10px">
        <Box style={{ marginTop: 0 }} width="100%">
          <Card spacing={1}>
            <CardHeader
              titleTypographyProps={{ variant: 'h6' }}
              title={title ? title : <Skeleton variant="rectangular" />}
            />
            <CardContent>
              <Grid container>
                {/* amazonリンクのあるなしと画像パス有無で描画を分けている */}
                <Grid item xs={5} className={classes.centerContent}>
                  {data ? (
                    image_path ? (
                      amazon_link ? (
                        <CardMedia
                          image={image_path}
                          className={classes.cardMedia}
                          onClick={(e) => amazonClick(e)}
                        />
                      ) : (
                        <CardMedia
                          image={image_path}
                          className={classes.cardMedia}
                        />
                      )
                    ) : (
                      <Box
                        bgcolor="#233"
                        className={classes.cardMedia}
                        style={{ textAlign: 'center' }}
                      >
                        <Typography
                          variant="subtitle1"
                          color="#fff"
                          style={{ marginTop: '80px' }}
                        >
                          {title}
                        </Typography>
                      </Box>
                    )
                  ) : (
                    <Skeleton
                      variant="rectangular"
                      style={{
                        height: mobileStyle ? '200px' : '290px',
                        width: mobileStyle ? '180px' : '200px',
                      }}
                    />
                  )}
                </Grid>
                <Grid item xs={7}>
                  <Box maxHeight="350px" overflow="scroll">
                    <CardContent spacing={1}>
                      {data ? (
                        <>
                          <Typography variant="body2">
                            著者：{author}
                          </Typography>
                          <Typography variant="body2">
                            出版社：{publisher}
                          </Typography>
                          <Typography variant="body2">
                            出版日：{publish_date}
                          </Typography>
                          <Typography variant="body2">
                            ページ数：{pages}
                          </Typography>
                          <Typography variant="body2">ISBN：{isbn}</Typography>
                          <Typography variant="body2" paragraph>
                            定価：{price}円
                          </Typography>
                          <CardActions>
                            <Grid container spacing={1}>
                              {amazon_link && (
                                <Grid item sm={6}>
                                  <Button
                                    variant="outlined"
                                    onClick={(e) => amazonClick(e)}
                                  >
                                    Amazonで購入
                                  </Button>
                                </Grid>
                              )}
                              {/* <Grid item sm={6}>
                              <Button variant="outlined">
                                <Link
                                  to={`../../guruguru/${isbn}`}
                                  style={{
                                    textDecoration: 'none',
                                    color: '#000',
                                  }}
                                >
                                  メモグラフ
                                </Link>
                              </Button>
                            </Grid> */}
                            </Grid>
                          </CardActions>
                          <Typography variant="body2" color="textSecondary">
                            紹介：
                            {text}
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Skeleton width="100%"></Skeleton>
                          <Skeleton width="100%"></Skeleton>
                          <Skeleton width="100%"></Skeleton>
                          <Skeleton width="100%"></Skeleton>
                          <Skeleton width="100%"></Skeleton>
                          <Skeleton width="100%"></Skeleton>
                        </>
                      )}
                    </CardContent>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          {/* <BookDetailOwner owners={owners} />
      <BookDetailOrder owners={owners} authState={v.authState} isbn={slug} /> */}
          <BookDetailNote
            isbn={slug}
            memoId={hash}
            title={{ title }}
            // memoData={memoData}
          />
        </Box>
      </Box>
    </>
  );
});

export { BookDetail };
