import React from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Breadcrumbs,
  Box,
  useMediaQuery,
  Link,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Link as RouterLink } from 'react-router-dom';
import { Login } from '../molecules/Login';
import { UseGetIdentifier } from '../../containers/auth';
import { useUpsertUserInfo } from '../../containers/UpsertUser';
import { SelectArea } from '../molecules/SelectArea';
import { SwitchState } from '../atoms/SwitchState';
import { Guide } from './Guide';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    // marginBottom: '90px',
  },
  title: {
    flexGrow: 1,
    color: '#fff',
    textDecoration: 'none',
  },
}));

const ButtonAppBar = () => {
  const classes = useStyles();
  const mobileStyle = useMediaQuery('(max-width:600px)');
  const userIdentifier = UseGetIdentifier();
  // userIdentifier.identifier && console.log(userIdentifier, '初期ログイン');
  const upsertUserIdentifier = useUpsertUserInfo();
  userIdentifier.identifier && upsertUserIdentifier(userIdentifier.identifier);
  const pages = ['books', 'notes', 'graph'];
  return (
    <>
      <Box className={classes.root} mb={mobileStyle ? 11 : 9}>
        <AppBar color="primary">
          <Toolbar>
            <Typography
              variant="h6"
              className={classes.title}
              to="/"
              component={RouterLink}
            >
              NoteQualia
            </Typography>
            {!mobileStyle && (
              <Breadcrumbs
                aria-label="breadcrumb"
                color={'#fff'}
                style={{ marginRight: '30px' }}
                separator=""
              >
                {pages.map((v) => (
                  <Link
                    to={`/${v}`}
                    component={RouterLink}
                    color="secondary.light"
                    underline="hover"
                  >
                    {v}
                  </Link>
                ))}
              </Breadcrumbs>
            )}
            <SwitchState />
            <Login />
          </Toolbar>
        </AppBar>
      </Box>
      {mobileStyle && <SelectArea />}
      <Guide />
    </>
  );
};

export { ButtonAppBar as Header };
