import React, { useState, useEffect, useContext } from 'react';
import { BooksNew } from '../organisms/BooksNew';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { AddBookBefore } from '../organisms/AddBook_Before';
import { AddBookBeforeNotLogin } from '../organisms/AddBookBeforeNotLogin';
// import { AuthContext } from '../../containers/auth';
import { GET_BOOKS } from '../../containers/GetBooks';
import { SearchBefore } from '../organisms/SearchBefore';
import { HelpIcon } from '../atoms/HelpIcon';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import LoadingCircle from '../atoms/LoadingCircle';

const useStyles = makeStyles(() => ({
  content: {
    margin: '0px 0 0 0',
    position: 'static',
  },
}));

const TempBookListsAll = React.memo((v) => {
  const [bookData, setBookData] = useState([]);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const classes = useStyles();
  const authState = v.authState;
  const { error, fetchMore } = useQuery(GET_BOOKS, {
    variables: {
      subject_code: '%',
      limit_number: 12,
    },
    onCompleted: (data) => {
      if (!data.books.length) {
        const { books } = data.books;
        // console.log(data.books.length, 'onCompletedの確認');
        setBookData(books);
      }
    },
  });

  const getBooksData = async () => {
    setOffset((prevOffset) => prevOffset + 12);
    const { data } = await fetchMore({
      variables: { limit_number: 12, offset_number: offset },
    });
    // 取得したdata件数が0の時はhasMoreをfalseにして、スクロールを終了
    // console.log(data);
    data.books.length === 0 && setHasMore(!hasMore);
    setBookData((prev) => [...prev, ...data.books]);
  };

  useEffect(() => {
    getBooksData();
  }, []);

  return (
    <Box className={classes.content}>
      {bookData && bookData.length <= 0 && <LoadingCircle />}
      {bookData && bookData.length > 0 && (
        <BooksNew
          data={bookData}
          nextFunc={() => getBooksData()}
          hasMore={hasMore}
        />
      )}

      <Box position="fixed" bottom="26%" right="4%">
        <Link to={`about`}>
          <HelpIcon />
        </Link>
      </Box>

      <Box position="fixed" bottom="15%" right="4%">
        <SearchBefore />
      </Box>

      <Box position="fixed" bottom="4%" right="4%">
        {authState.status === 'in' ? (
          <AddBookBefore />
        ) : (
          <AddBookBeforeNotLogin />
        )}
      </Box>
    </Box>
  );
});

export { TempBookListsAll };
