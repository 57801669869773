// メモ入力エリア
// tabを管理 フォームは子コンポーネントで管理

import React, {
  useState,
  useMemo,
  useEffect,
  useCallback,
  useContext,
} from 'react';
import { AuthContext } from '../../containers/auth';
import { Box, Typography, Tabs, Tab, AppBar } from '@mui/material';
import { GET_MEMOS } from '../../containers/GetMemos';
import { useQuery } from '@apollo/client';
// import { LocalConvenienceStoreOutlined } from '@mui/icons-material';
import { BookDetailNoteChild } from './BookDetailNoteChild';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const MemoList = React.memo((props) => {
  let filterdMemos = props.memos;
  // console.log(filterdMemos, 'memoLIstの中身');

  let group = useMemo(
    () =>
      filterdMemos.reduce((result, current) => {
        const element = result.find((p) => p.user_id === current.user_id);
        if (element) {
          element.noteData.push({
            memo_id: current.memo_id,
            memo_title: current.memo_title,
            memo_text: current.memo_text,
            updated_at: current.updated_at,
            isbn: current.isbn,
          });
        } else {
          result.push({
            user_id: current.user_id,
            displayName:
              current.memos_to_users && current.memos_to_users.display_name,
            noteData: [
              {
                memo_id: current.memo_id,
                memo_title: current.memo_title,
                memo_text: current.memo_text,
                updated_at: current.updated_at,
                isbn: current.isbn,
              },
            ],
          });
        }
        return result;
      }, []),
    [filterdMemos]
  );

  // console.log(group, 'groupの確認！！！！');
  // console.log(props.uid, 'propsの確認！！！！');

  /**tabs渡すデータ(group)はuid別のメモ一覧になっている,ログインしているuidが含まれている場合は、ログインuidを先頭にするようにソートする
   * ログインしているuidの位置を特定し、先頭[0]と入れ替える
   */
  const sortIndex = group.findIndex((arr) => arr.user_id === props.uid);
  if (sortIndex !== 0 && sortIndex !== -1) {
    // const sortedGroup = group
    const temp = group[0];
    group[0] = group[sortIndex];
    group[sortIndex] = temp;
  }
  // console.log(group, '並び替えた？');

  const a11yProps = (index) => {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  };

  // tabのフォーカスを指定している
  const [value, setValue] = useState(0);

  // hashMemoDisplayNameがあればtabを切り替える
  // reduce後のデータからindexを探して、tabのvalueにセット
  const memoUserIndex = group.findIndex(
    (v) => v.displayName === props.hashMemoDisplayName
  );
  // console.log(memoUserIndex, 'test結果');
  // console.log(props.hashMemoDisplayName);

  // タブ切り替え後のスクロールを管理
  // スクロール先のmemoId,divと一致してればrefを生成する
  const trgRef = props.hashMemoId;

  const [memoRef, setMemoRef] = useState(null);

  const ref = React.createRef();
  const scrollToMemo = useCallback(() => {
    ref &&
      ref.current &&
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
  }, [ref]);

  useEffect(() => {
    memoUserIndex >= 0 && setValue(memoUserIndex);
    setMemoRef(ref);
    setTimeout(() => scrollToMemo(), 200);
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      {/* 新規入力フォーム、未ログイン時は非表示 */}
      {props.uid && (
        <BookDetailNoteChild
          // key={index}
          // index={index}
          title={props.title}
          noteData={''}
          updated_at={null}
          role={true}
          isbn={props.isbn}
          uid={props.uid}
          user_id={props.uid}
        />
      )}
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          {group.map((vUid, index) => (
            <Tab
              label={vUid.displayName}
              {...a11yProps(index)}
              key={vUid.user_id}
            >
              {vUid.displayName}
            </Tab>
          ))}
        </Tabs>
      </AppBar>
      {group.map((vUid, index) => (
        <TabPanel key={vUid} value={value} index={index}>
          {vUid.noteData.map((v2, index2) => (
            <>
              <BookDetailNoteChild
                title={props.title}
                id={v2.memo_id}
                key={v2.memo_id}
                updated_at={v2.updated_at}
                index={index2}
                noteData={v2}
                isbn={v2.isbn}
                uid={props.uid}
                user_id={vUid.user_id}
              />
              {v2.memo_id === trgRef && (
                <div id={v2.memo_id} key={v2.memo_id} ref={memoRef}></div>
              )}
            </>
          ))}
          {/* {console.log(vUid.noteData, 'mapの中')} */}
        </TabPanel>
      ))}
    </>
  );
});

const BookDetailNote = React.memo((props) => {
  // hashパラメータの#を除外
  const hashMemoId = props.memoId.substring(1, props.memoId.length);
  // console.log(hashMemoId);

  const { authState } = useContext(AuthContext);

  const { loading, error, data } = useQuery(GET_MEMOS, {
    variables: { isbn: props.isbn },
  });

  if (loading) {
    return <div>Loading...</div>;
  }
  if (error) {
    console.error(error);
    return <div>Error!</div>;
  }

  // console.log(data.memos);

  // hashについているmemoIdでmemoデータからdisplayNameを取得
  const hashMemoDisplayName =
    data.memos &&
    hashMemoId &&
    data.memos
      .map((v) =>
        v.memo_id === hashMemoId ? v.memos_to_users.display_name : null
      )
      .filter(Boolean)[0];

  // hashMemoDisplayName && console.log(hashMemoDisplayName);
  // アンカーリンク用にhashMemoIdのdisplayNameを特定する

  return (
    <MemoList
      memos={data.memos}
      isbn={props.isbn}
      uid={(authState.user || '').uid}
      hashMemoId={hashMemoId}
      hashMemoDisplayName={hashMemoDisplayName}
      title={props.title}
    />
  );
});

export { BookDetailNote };
