// 登録が0件の時のみ表示、addBookBeforeをほぼコピー
// dialogは使っていないため、onCloseは本当は不要だが、子コンポーネントのpropで渡さないとエラーになるため、暫定的に設定している。
import React, { useState } from 'react';
import {
  Typography,
  Box,
  Card,
  CardContent,
  CardActions,
  CardHeader,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { InputIsbnFiled } from '../molecules/AddBook_InputIsbnFiled';
import { AddBookScanner } from '../molecules/AddBook_Scanner';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    // marginTop: '80px',
    justifyContent: 'center',
    display: 'flex',
  },
  card: {
    // width: '90vw',
  },
  title: {
    flexGrow: 1,
    color: '#fff',
    textDecoration: 'none',
  },
}));

const useAddBookBefore = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  // console.log(GetBooksInfo('9784047358522'));
  return {
    classes,
    open,
    handleClickOpen,
    handleClose,
  };
};

const FirstAdd = () => {
  const { classes, open, handleClickOpen, handleClose } = useAddBookBefore();
  return (
    <Box className={classes.root} pt={1}>
      <Card variant="outlined">
        <CardHeader
          // title="STEP1"
          // titleTypographyProps={{
          //   fontSize: 12,
          //   mb: -0.5,
          //   color: 'text.secondary',
          // }}
          subheader="メモする本を登録"
          subheaderTypographyProps={{ variant: 'h6', color: 'text.main' }}
          style={{ marginBottom: '-20px' }}
          // action={
          // <IconButton onClick={changeLocalStorage}>
          //   <CloseSharpIcon />
          // </IconButton>
          // }
        />
        <CardContent className={classes.card}>
          <Typography variant="body2" gutterBottom>
            ISBNコードのスキャンか入力で、メモしたい本を登録できます。
          </Typography>
          <CardActions
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              marginTop: '15px',
            }}
          >
            <AddBookScanner onclose={handleClose} />
            <InputIsbnFiled onclose={handleClose} />
          </CardActions>
        </CardContent>
      </Card>
    </Box>
  );
};

export { FirstAdd };
