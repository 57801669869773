// 動的ルーティングでurl(ISBN)を取得して、クエリを発行し、返ってきた情報を表示する
import React, { memo } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Typography, Box } from '@mui/material';
import { Skeleton } from '@mui/material';
import { useSearchBooksAndMemos } from '../../containers/SearchBooksAndMemos';
import styled from 'styled-components';
import LoadingCircle from '../atoms/LoadingCircle';

const BookItemWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const BookItem = styled.div`
  padding: 5px;
  .title {
    height: 160px;
    width: 120px;
    // max-width: 100%;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;
  }
`;

const BookImg = styled.img`
  margin-top: 5px;
  height: 160px;
  // max-width: 150px;
  // max-width: 100%;
  object-fit: cover;
  filter: drop-shadow(0 0 0.15rem #d5dee0);
`;

const ResultBook = memo((v) => {
  return (
    <BookItem>
      {!v && <LoadingCircle />}
      <Link to={`../../book/${v.props.isbn}`}>
        {v.props.image_path ? (
          <BookImg src={v.props.image_path} alt={v.props.title} />
        ) : (
          <>
            <Box
              bgcolor="#233"
              style={{
                padding: 0,
                marginTop: '5px',
                height: '160px',
                width: '110px',
                textAlign: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography
                variant="body2"
                color="secondary"
                style={{ paddingTop: '50px' }}
              >
                {v.props.title}
              </Typography>
            </Box>
          </>
        )}
      </Link>
    </BookItem>
  );
});

// inputからメモ・タイトルの検索ワードを受け取る
const SearchResult = memo((v) => {
  const resultData = useSearchBooksAndMemos(v);

  const resultArr =
    resultData &&
    resultData.books &&
    resultData.books.length !== 0 &&
    resultData.books.map((v) => <ResultBook props={v} />);
  // console.log(resultArr, 'mapテスト');

  return (
    <>
      {resultData && resultData.books && resultData.books.length !== 0 && (
        <BookItemWrap>{resultArr}</BookItemWrap>
      )}
      {resultData && resultData.books && resultData.books.length === 0 && (
        <p>該当する本が見つかりませんでした。</p>
      )}
    </>
  );
});

export { SearchResult };
