import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  HttpLink,
} from '@apollo/client';
import { CssBaseline } from '@mui/material';
import { AuthContext } from './containers/auth';
import React, { useContext } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { BookLists } from './components/pages/BookLists';
import { Account } from './components/pages/Account';
import { Header } from './components/organisms/Header';
import { BookDetail } from './components/molecules/BookDetail';
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles';
import { Graph } from './components/pages/Graph';
import { NoteLists } from './components/pages/NoteLists';
import { About } from './components/pages/About';
import { Tos } from './components/pages/Tos';
import { Policy } from './components/pages/Policy';
// import { relayStylePagination } from '@apollo/client/utilities';
import LodingCircle from './components/atoms/LoadingCircle';

const createApolloClient = (authState) => {
  const isIn = authState.status === 'in';
  const headers = isIn ? { Authorization: `Bearer ${authState.token}` } : {};
  return new ApolloClient({
    link: new HttpLink({
      uri: process.env.REACT_APP_HASURA_ENDPOINT,
      headers,
    }),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            isbn: {
              merge(existing, incoming, { readField }) {
                const merged = existing ? { ...existing } : {};
                incoming.ids.forEach((item) => {
                  merged[readField('id', item)] = item;
                });
                return {
                  cursor: incoming.cursor,
                  merged,
                };
              },
              read(existing) {
                if (existing) {
                  return {
                    cursor: existing.cursor,
                    merged: Object.values(existing.merged),
                  };
                }
              },
            },
          },
        },
      },
    }),
  });
};

const App = () => {
  const { authState } = useContext(AuthContext);
  const client = createApolloClient(authState);

  const theme = createTheme({
    type: 'light',
    palette: {
      primary: {
        main: '#33151b',
      },
      secondary: {
        light: '#fff',
        main: '#ec407a',
        // dark: '#586572',
        // contrastText: '#000',
      },
      background: {
        // default: '#000',
      },
    },
    typography: {
      button: {
        textTransform: 'none',
      },
    },
  });
  return (
    <ApolloProvider client={client}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            {authState.status === 'loading' ? (
              <LodingCircle />
            ) : (
              <>
                <CssBaseline />
                <Header />
                <Routes>
                  <Route path="/" element={<BookLists />} />
                  <Route path="/notes" element={<NoteLists />} />
                  <Route path="/graph" element={<Graph />} />
                  <Route path="/guruguru" element={<Graph />} />
                  <Route
                    path="account/:slug"
                    element={
                      authState.status === 'in' ? (
                        <Account />
                      ) : (
                        <Navigate to="/" replace />
                      )
                    }
                  />
                  <Route path="about" element={<About />} />
                  <Route path="tos" element={<Tos />} />
                  <Route path="policy" element={<Policy />} />

                  <Route path="/book/:slug">
                    <BookDetail />
                  </Route>

                  <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
              </>
            )}
          </BrowserRouter>
        </ThemeProvider>
      </StyledEngineProvider>
    </ApolloProvider>
  );
};

export default App;
