import React from 'react';
import {
  Button,
  Grid,
  Box,
  ButtonBase,
  Typography,
  Chip,
  Stack,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
// import Stack from '@mui/material/Stack';
import { useNavigate, useLocation } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  wrap: {
    marginTop: '0px',
    width: '100vw',
    hight: '80px',
    justifyContent: 'center',
    position: 'fixed',
    zIndex: '666',
  },
  content: {
    margin: '-24px auto',
    width: '90vw',
    position: 'relative',
    // backgroundColor: '#000',
  },
  item: {
    width: '40%',
    backgroundColor: 'rgba(255,255,255,0.9)',
  },
}));

const SelectArea = () => {
  const classes = useStyles();
  const pages = ['books', 'notes', 'graph'];
  const pagePath = useLocation().pathname;
  const excludeParam = ['about', 'tos', 'policy', 'account'];
  // pagePathの除外チェック　一部のページにはメニューを出さない 除外はtrue
  const isExclude = () => {
    let testN = excludeParam.length;
    excludeParam.forEach((element) => {
      testN += pagePath.indexOf(element);
    });
    return testN > 0;
  };
  const navigate = useNavigate();
  const navigatePage = (v) => {
    navigate(`../${v}`);
  };
  return (
    <>
      {!isExclude() ? (
        <Box className={classes.wrap}>
          <Stack
            className={classes.content}
            direction="row"
            spacing={4}
            justifyContent="center"
          >
            {pages.map((v) => (
              <Chip
                onClick={() => navigatePage(v)}
                className={classes.item}
                label={v.toUpperCase()}
                variant="outlined"
                size="small"
                style={{
                  filter: pagePath.indexOf('graph') > 0 ? 'invert()' : '',
                }}
              />
            ))}
          </Stack>
        </Box>
      ) : null}
    </>
  );
};

export { SelectArea };
