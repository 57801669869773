import React from 'react';
import { CircularProgress, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Box, Paper, Typography } from '@mui/material';
import { PastTime } from '../../containers/PastTime';
import InfiniteScroll from 'react-infinite-scroll-component';
// import { FirstAdd } from '../organisms/FirstAdd';
// containersのGetBooksのカスタムフックから本情報一覧を取得して描画
// GetBooksはload,error時はnullを返す

const moveParent = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.05,
    },
  },
};

const moveItem = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

// Accept one of the books data
const NotesChild = React.memo((v) => {
  return (
    <>
      <motion.li variants={moveItem}>
        <Link
          to={`../../book/${v.props.isbn}#${v.props.memo_id}`}
          style={{ textDecoration: 'none' }}
        >
          <Box spacing={3} m={2} width="94vw">
            <Paper>
              <Grid container spacing={3}>
                <Grid item xs={1}></Grid>

                <Grid item xs={3}>
                  {v.props.memos_to_books ? (
                    <img
                      src={v.props.memos_to_books.image_path}
                      width="90px"
                      alt="book"
                    />
                  ) : (
                    v.props.title
                  )}
                </Grid>

                <Grid item xs={7}>
                  <Typography variant="subtitle2">
                    {v.props.memo_title}
                  </Typography>
                  <Typography variant="body2">{v.props.memo_text}</Typography>
                  <br />
                  <Typography variant="body2" align="right">
                    {v.props.memos_to_users &&
                      v.props.memos_to_users.display_name}
                  </Typography>
                  <Typography variant="body2" align="right">
                    {v.props.updated_at && (
                      <PastTime time={v.props.updated_at} />
                    )}
                  </Typography>
                </Grid>

                <Grid item xs={1}></Grid>
              </Grid>
            </Paper>
          </Box>
        </Link>
      </motion.li>
    </>
  );
});

// Accept all books data
const Notes = React.memo((props) => {
  const notesData = props.data ?? '';
  // console.log(notesData[0], '読み込み前のデータ確認');

  return (
    <>
      {/* {notesData.length === 0 && <FirstAdd />} */}
      {typeof notesData[0] !== 'undefined' && (
        <Box style={{ width: '100vw' }}>
          <motion.ul
            variants={moveParent}
            initial="hidden"
            animate="visible"
            style={{ margin: 0, padding: 0 }}
          >
            <InfiniteScroll
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
                listStyle: 'none',
              }}
              dataLength={notesData.length}
              next={() => props.nextFunc()}
              // hasMoreはデフォルトはfalseのはず、、なのでアカウント画面でnextFuncを渡さなくてもエラーにならない
              hasMore={props.hasMore}
              loader={<CircularProgress />}
              // endMessage={
              //   <h1 style={{ textAlign: 'center' }}>
              //     <b>You have seen it all</b>
              //   </h1>
              // }
            >
              {notesData &&
                notesData.map((v) => <NotesChild key={v.title} props={v} />)}
            </InfiniteScroll>
          </motion.ul>
        </Box>
      )}
    </>
  );
});

export { Notes };
