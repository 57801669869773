import React from 'react';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import Fab from '@mui/material/Fab';

// もらったpropsをonClickとして実行する
const SearchIcon = (props) => {
  return (
    <Fab
      color="secondary"
      position="absolute"
      top={40}
      left="40%"
      aria-label="add"
      onClick={props.click}
    >
      <SearchOutlinedIcon />
    </Fab>
  );
};

export { SearchIcon };
