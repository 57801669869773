import { Typography } from '@mui/material';
import React from 'react';
import styled, { keyframes } from 'styled-components';
import { useGetLatestMemos } from '../../containers/GetLatestMemos';
import { Link } from 'react-router-dom';

const TextAnimation = keyframes`
from   { transform: translate(0%);}
99%,to { transform: translate(-100%);}
`;

const AnimatedText = styled.div`
  paddingleft: 100%;
  animation-name: ${TextAnimation};
  animation-duration: 20s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
`;

// const AnimatedContainer = styled.div``;

const BooksCaption = React.memo((v) => {
  const latestMemo = useGetLatestMemos();

  // console.log(latestMemo && latestMemo.memos[0]);
  return (
    <>
      {latestMemo && latestMemo.memos[0] && (
        <Typography noWrap variant="subtitle2">
          {/* <AnimatedText> */}
          {v.props}
          <Link
            style={{ textDecoration: 'none', color: '#000' }}
            to={`../../book/${latestMemo.memos[0].isbn}#${latestMemo.memos[0].memo_id}`}
          >
            <span style={{ fontStyle: 'italic' }}>
              {`${latestMemo.memos[0].memo_title} ーー${latestMemo.memos[0].memo_text}`}{' '}
              「{latestMemo.memos[0].memos_to_books.title}」
            </span>
          </Link>
          {/* </AnimatedText> */}
        </Typography>
      )}
    </>
  );
});

export { BooksCaption };
