// ログインしているユーザーが操作できる本を追加するボタン
import React, { useState, useCallback, useMemo, useEffect } from 'react';
import {
  Dialog,
  Box,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Button,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { InputIsbnFiled } from '../molecules/AddBook_InputIsbnFiled';
import { SearchIcon } from '../atoms/SearchIcon';
// import { AddBookScanner } from '../molecules/AddBook_Scanner';
import { useForm, Controller } from 'react-hook-form';
import styled from 'styled-components';
import { SearchResult } from '../molecules/SearchResult';

const SearchInput = styled.input`
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #000;
  padding: 10px 15px;
  font-size: 16px;
`;

const SearchLabel = styled.label`
  line-height: 2;
  text-align: left;
  display: block;
  margin-bottom: 5px;
  color: #000;
  font-size: 14px;
  font-weight: 200;
`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  contents: {
    textAlign: 'center',
  },
  border: {
    border: 'solid 1px',
  },
  space: {
    margin: '10px',
  },
});

const useSearchBefore = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return {
    classes,
    open,
    handleClickOpen,
    handleClose,
  };
};

const SearchBefore = React.memo(() => {
  const { classes, open, handleClickOpen, handleClose } = useSearchBefore();
  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    mode: 'onBlur', // "onChange"
  });
  const [resultData, setResultData] = useState('');

  // 検索結果の初期化、モーダルを開閉を検知して初期化する
  useEffect(() => {
    return () => {
      setResultData('');
    };
  }, [open]);

  // console.log('searchBeforeのレンダリング');

  // インプット入力欄の入力文字数をチェック
  const trimedLength = (arr) => {
    let res = 0;
    arr.forEach((e) => (res += getValues(e).trim().length));
    return res;
  };

  const onSubmit = (data) => {
    if (trimedLength(['memo', 'bookTitle']) !== 0) {
      setResultData(data);
      setValue('memo', '');
      setValue('bookTitle', '');
    } else {
      alert('検索欄への入力が必須です。');
    }
    // trimedLength(['memo', 'bookTitle'])
    // console.log(getValues('memo'));
    // console.log(getValues('bookTitle'));
  };
  // console.log(errors);

  return (
    <Box>
      <SearchIcon click={handleClickOpen} />
      <Dialog
        // fullScreen
        fullWidth={'lg'}
        maxWidth={'lg'}
        open={open}
        onClose={handleClose}
        // TransitionComponent={Transition}
      >
        <DialogTitle mb={-1.5}>検索</DialogTitle>
        <DialogContent className={classes.contents}>
          {/* フォーム */}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box>
              <Box>
                <SearchLabel>メモ</SearchLabel>
                <SearchInput
                  type="text"
                  placeholder="メモに使われた単語で検索"
                  {...register('memo', {
                    maxLength: { value: 30, message: 'error message' },
                  })}
                />
              </Box>
              <Box>
                <SearchLabel>書籍名</SearchLabel>
                <SearchInput
                  type="text"
                  placeholder="本のタイトルを検索"
                  {...register('bookTitle', {
                    maxLength: 40,
                  })}
                />
              </Box>
              {/* <Box >
                <SearchLabel>書籍ジャンル</SearchLabel>
                <Select {...register}>
                  <option value="aa">指定なし</option>
                  <option value="ab">ab</option>
                  <option value="ac">ac</option>
                  <option value="ad">ad</option>
                </Select>
              </Box> */}
              <Box p={1}>
                <Button variant="contained" type="submit" value="検索する">
                  検索する
                </Button>
              </Box>
            </Box>
          </form>

          {resultData && <SearchResult {...resultData} />}

          <Button variant="outlined" onClick={handleClose}>
            閉じる
          </Button>
        </DialogContent>
      </Dialog>
    </Box>
  );
});

export { SearchBefore };
