// When this component accept data from openDB, pass the props to the Modal
import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

/** Before insert bookData and owner table, confirm registeration
 *
 * @param {*} props  -bookData,open,handleClose
 *
 */
const AddBookDetailModal = React.memo((props) => {
  console.log(props, 'modalのprop確認');
  return props.bookData !== '' ? (
    <Dialog
      open={props.open}
      onClose={props.onclose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      TransitionComponent={Transition}
    >
      <DialogTitle id="alert-dialog-title">
        <span className="title">{props.bookData.title}</span>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <img src={props.bookData.image_path} alt={props.bookData.title} />
          <div>
            <div>著者：{props.bookData.author}</div>
            {/* <div>ページ：{props.bookData.pages}</div> */}
            <div>出版社：{props.bookData.publisher}</div>
            <div>出版日：{props.bookData.publish_date}</div>
            <div>定価：{props.bookData.price}円</div>
            <div>紹介：</div>
            {props.bookData.text}
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {/* {props.bookExist ? '※登録済みの本です。' : null}
        <Button onClick={props.onclose} color="primary">
          登録しない
        </Button> */}
        {/* regist関数は引数付きで渡してるのでアロー関数で渡している */}
        <Button
          onClick={() => {
            props.regist(props.bookData);
          }}
          color="primary"
          autoFocus
          // disabled={props.bookExist}
        >
          メモを書く
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;
});

export { AddBookDetailModal };
