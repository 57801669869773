// Rendering book cover, title and author
import React from 'react';
import styled from 'styled-components';
import { Typography } from '@mui/material';

const BookItemDiv = styled.div`
  position: relative;
  width: 32vw;
  padding: 5px;
  text-align: center;
  // background-color: rgba(0, 0, 0, 0.3);
  // box-shadow: 6px 6px 9px #f2f2f2, -6px -6px 9px #ffffff;
  img {
    margin-top: 5px;
    height: 180px;
    // max-width: 180px;
    max-width: 100%;
    object-fit: cover;
    filter: drop-shadow(0 0 0.15rem #d5dee0);
  }
  .bookInfoBack {
    // position: absolute;
    // // width: 30vw;
    // top: 75%;
    // overflow: hidden;
    // white-space: nowrap;
    // text-overflow: ellipsis;
    // background-color: rgba(0, 0, 0, 0.3);
    // color: #000;
  }
  .title {
    position: absolute;
    height: 180px;
    width: 30vw;
    top: 90px;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;
  }
`;

const BookItem = React.memo((v) => {
  // console.log(v.props.image_path, 'image');
  return (
    <BookItemDiv>
      <>
        <div>
          {v.props.image_path ? (
            <img src={v.props.image_path} alt={v.props.title} />
          ) : (
            <>
              {/* <img src={dummy} alt={v.props.title} /> */}
              <div className="title">
                <Typography variant="body1">{v.props.title}</Typography>
              </div>
            </>
          )}
        </div>
      </>
    </BookItemDiv>
  );
});

export { BookItem };
