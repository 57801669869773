import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Input,
  Box,
  Grid,
  ButtonBase,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { UPDATE_MEMO, ADD_MEMO, REMOVE_MEMO } from '../../containers/AddMemo';
import CreateIcon from '@mui/icons-material/Create';
import { GET_MEMOS } from '../../containers/GetMemos';
import { useMutation } from '@apollo/client';
import { Share } from 'react-twitter-widgets';
import { TwitterShareButton, TwitterIcon } from 'react-share';
import { PastTime } from '../../containers/PastTime';

const stringProcessing = (targetString) => {
  const excludeChar = [' ', '　', '「', '」', '―'];
  let res = [...targetString]
    .filter((char) => excludeChar.indexOf(char) === -1)
    .join('');
  return res;
};

const useStyles = makeStyles((theme) => ({
  centerContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 10,
  },
  input: {
    '&::disabled': {
      color: '#000',
    },
  },
  rotate: {
    // color: "#fff",
    height: '100%',
    // backgroundColor: "#424"
  },
  buttonFullHeight: {
    maxWidth: '100%',
    maxHeight: '100%',
    minWidth: '100%',
    minHeight: '100%',
  },
  buttonRightIcon: {
    maxWidth: '100%',
    maxHeight: '25%',
    minWidth: '100%',
    minHeight: '25%',
  },
}));

/**
 * propsでmemoId,memoTitle,memoText,isbn,uidを取得
 * propsで取得したデータをsetValueで初期値として設定する
 * @props　v.role 新規入力(true)か既存編集(false)フォームかの判別に使用
 * @returns メモ入力エリアを返す
 */
const BookDetailNoteChild = React.memo((v) => {
  // console.log(v, '受け取ったデータ！');
  const bookTitle = v && v.title.title;
  // console.log(bookTitle, 'title');

  const { register, control, handleSubmit, setValue } = useForm({
    mode: 'onBlur', // "onChange"
  });
  // editボタン表示の管理
  const [isEdited, setIsEdited] = useState(true);

  // console.log(v);

  // 新規追加フォームのクリアに使用
  const formClear = () => {
    setValue('Title', '');
    setValue('Memo', '');
  };

  const updateCache = (cache, { data }) => {
    // Fetch the todos from the cache
    const existingTodos = cache.readQuery({
      query: GET_MEMOS,
      variables: {
        isbn: v.isbn,
      },
    });
    // Add the new todo to the cache
    const newTodo = data.insert_memos.returning[0];
    // console.log(data, 'datainsertの確認');
    // console.log(existingTodos, 'existingDataの確認');

    cache.writeQuery({
      query: GET_MEMOS,
      variables: {
        isbn: v.isbn,
      },
      data: { memos: [newTodo, ...existingTodos.memos] },
    });
  };

  const [updateMemo] = useMutation(UPDATE_MEMO, {
    update: updateCache,
  });

  const [addMemo] = useMutation(ADD_MEMO, {
    update: updateCache,
    onCompleted: formClear,
  });

  const [removeMemoMutation] = useMutation(REMOVE_MEMO);

  // console.log(v.uid, 'memo内のログインチェック');
  // v.uidがログインチェックになる

  // 初期のデータ表示に使用
  useEffect(() => {
    if (v) {
      setValue('Title', v.noteData && v.noteData.memo_title);
      setValue('Memo', v.noteData && v.noteData.memo_text);
    }
  }, [v]);

  // Saveクリック時はgraphQLを発行、Editクリック時はisEditedを変更し、編集可能にする
  // isEditedに応じて挙動を変更
  const onSubmit = (data) => {
    if (isEdited) {
      setIsEdited((prevState) => !prevState);
    } else {
      // alert(JSON.stringify(data));
      // console.log(v, 'memoのデータ');
      const trgData = {
        memo_id: v.memo_id && v.memo_id,
        user_id: v.uid,
        isbn: v.isbn,
        memo_title: data.Title,
        memo_text: data.Memo,
      };

      // 新規追加フォームか既存編集フォームかでクエリを変更
      if (v.role) {
        addMemo({
          variables: {
            user_id: v.uid,
            isbn: v.isbn,
            memo_title: data.Title,
            memo_text: data.Memo,
          },
        });
      } else {
        updateMemo({
          variables: {
            memo_id: v.noteData.memo_id,
            user_id: v.uid,
            isbn: v.isbn,
            memo_title: data.Title,
            memo_text: data.Memo,
          },
        });
      }

      // console.log(trgData, '編集時のメモ中身');
      setIsEdited((prevState) => !prevState);
    }
  };

  // メモ削除前の確認モーダル
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseDelete = () => {
    setOpen(false);
    deleteMemo();
  };

  // メモ削除処理
  const deleteMemo = () => {
    // e.preventDefault();
    // e.stopPropagation();
    removeMemoMutation({
      variables: { memo_id: v.noteData.memo_id },
      optimisticResponse: true,
      update: (cache) => {
        const existingTodos = cache.readQuery({
          query: GET_MEMOS,
          variables: {
            isbn: v.isbn,
          },
        });
        const newTodos = existingTodos.memos.filter(
          (t) => t.memo_id !== v.noteData.memo_id
        );
        cache.writeQuery({
          query: GET_MEMOS,
          variables: {
            isbn: v.isbn,
          },
          data: { memos: newTodos },
        });
      },
    });
  };

  // メモ編集キャンセル
  const cancelMemo = () => {
    setIsEdited((prevState) => !prevState);
    setValue('Title', v.noteData ? v.noteData.memo_title : '');
    setValue('Memo', v.noteData ? v.noteData.memo_text : '');
  };

  // メモ表示非表示の切り替え
  const changeVisibility = () => {
    alert('Changed visibility');
  };
  const classes = useStyles();
  return (
    <Box
      className={classes.centerContent}
      boxShadow={1}
      id={v.noteData.memo_id}
    >
      {/* <AnchorLink href={`#${v.noteData.memo_id}`}></AnchorLink> */}
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <Grid container>
          {/* editとsaveの切り替えボタン */}
          <Grid item xs={1}>
            <Box className={classes.rotate}>
              {/* ログイン状態によって表示切り替え、ログインuidとmemoのuser_idが一致していれば編集可能 */}
              {v.user_id === v.uid ? (
                // ログイン状態時
                <ButtonBase
                  className={classes.buttonFullHeight}
                  variant="outlined"
                  type="submit"
                  color="secondary"
                >
                  <Typography
                    variant="h6"
                    style={{ transform: 'rotate(-90deg)' }}
                  >
                    {isEdited ? <CreateIcon /> : 'Save'}{' '}
                  </Typography>
                </ButtonBase>
              ) : (
                // ログアウト状態時
                // <ButtonBase className={classes.buttonFullHeight}>
                //   <CreateIcon />
                // </ButtonBase>
                <></>
              )}
            </Box>
          </Grid>
          {/* メモテキスト入力エリア */}
          <Grid item xs={10}>
            <Controller
              render={({ field }) => (
                <Input
                  required
                  {...field}
                  placeholder="---Title--"
                  disableUnderline={true}
                  style={{ width: '100%', color: '#000' }}
                  className={classes.input}
                  disabled={isEdited}
                  // {...register('title', {
                  //   required: true,
                  // })}
                />
              )}
              name="Title"
              control={control}
            />
            <Controller
              render={({ field }) => (
                <Input
                  required
                  {...field}
                  placeholder="---Memo-------------------------------------------------------------------------------------------------------------------------------------------------------"
                  disableUnderline={true}
                  multiline
                  minRows={4}
                  style={{ width: '100%', color: '#000' }}
                  className={classes.input}
                  // defaultValue={v.noteData.memo_text}
                  disabled={isEdited}
                />
              )}
              name="Memo"
              control={control}
            />
            <Box style={{ textAlign: 'right' }}>
              <Typography variant="body2" color="textSecondary">
                {v.updated_at && <PastTime time={v.updated_at} />}
              </Typography>
            </Box>
          </Grid>

          {/* ログインしており、edit前(未編集状態)はアイコン表示、編集中はキャンセルを表示 */}
          <Grid item xs={1}>
            {v.user_id === v.uid && isEdited ? (
              <Box className={[classes.rotate, classes.buttonArea]}>
                <ButtonBase
                  style={{
                    maxWidth: '100%',
                    maxHeight: '50%',
                    minWidth: '100%',
                    minHeight: '50%',
                  }}
                >
                  {v.noteData !== '' && (
                    <TwitterShareButton
                      title={v.noteData.memo_title.concat(
                        `\n`,
                        v.noteData.memo_text
                      )}
                      url={`https://notequalia.com/book/${v.isbn}#${v.noteData.memo_id}`}
                      hashtags={[bookTitle && stringProcessing(bookTitle)]}
                    >
                      <TwitterIcon
                        size={23}
                        round={true}
                        // bgStyle={'none'}
                        // iconFillColor={'#000'}
                      />
                    </TwitterShareButton>
                  )}
                </ButtonBase>

                {v.role ? null : (
                  <>
                    <ButtonBase
                      className={classes.buttonRightIcon}
                      onClick={() => {
                        handleClickOpen();
                      }}
                    >
                      <DeleteOutlineOutlinedIcon fontSize="small" />
                    </ButtonBase>
                  </>
                )}
              </Box>
            ) : (
              // ログインしていなければ非表示
              v.user_id === v.uid && (
                <Box className={classes.rotate}>
                  <ButtonBase
                    className={classes.buttonFullHeight}
                    onClick={() => {
                      cancelMemo();
                    }}
                  >
                    <Typography
                      variant="h6"
                      style={{ transform: 'rotate(-90deg)' }}
                    >
                      Cancel
                    </Typography>
                  </ButtonBase>
                </Box>
              )
            )}
          </Grid>
        </Grid>
      </form>
      <>
        <div>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {'本当にメモを削除しますか？'}
            </DialogTitle>
            <DialogActions>
              <Button onClick={handleClose} color="primary" autoFocus>
                削除しない
              </Button>
              <Button onClick={handleCloseDelete} color="primary">
                削除する
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </>
    </Box>
  );
});

export { BookDetailNoteChild };
