// ログイン時は自分だけか全体の情報を表示するかを切り替えるswitchを用意
import React, { useState, useContext } from 'react';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { AuthContext } from '../../containers/auth';
import { makeVar, useReactiveVar } from '@apollo/client';
import { useLocation } from 'react-router';

export const switchStateVar = makeVar(true);

const SwitchState = () => {
  const { authState } = useContext(AuthContext);
  const switchResult = useReactiveVar(switchStateVar);

  const handleChange = (event) => {
    switchStateVar(!switchResult);
  };

  const pagePath = useLocation().pathname;
  const excludeParam = ['about', 'tos', 'policy', 'account', 'book'];
  // pagePathの除外チェック　一部のページにはメニューを出さない 除外はtrue
  const isExclude = () => {
    let testN = excludeParam.length;
    excludeParam.forEach((element) => {
      testN += pagePath.indexOf(element);
    });
    return testN > 0;
  };

  return (
    <>
      {authState.status === 'in' && !isExclude() ? (
        <FormControl component="fieldset" variant="standard">
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={switchResult}
                  onChange={handleChange}
                  name="own"
                  color="secondary"
                />
              }
              label="Own"
              style={{ color: switchResult ? '#fff' : '#A1A0A2' }}
            />
          </FormGroup>
        </FormControl>
      ) : null}
    </>
  );
};
export { SwitchState };
