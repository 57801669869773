import React from 'react';
import { BookItem } from '../molecules/BookItem';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import InfiniteScroll from 'react-infinite-scroll-component';
import LoadingCircle from '../atoms/LoadingCircle';
import { FirstAdd } from '../organisms/FirstAdd';
// containersのGetBooksのカスタムフックから本情報一覧を取得して描画
// GetBooksはload,error時はnullを返す

const moveParent = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.05,
    },
  },
};

const moveItem = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

// Accept one of the books data
const BooksChild = React.memo((v) => {
  return (
    <>
      <motion.li variants={moveItem}>
        <Link to={`../../book/${v.props.isbn}`} style={{ color: '#000' }}>
          <BookItem {...v} />
        </Link>
      </motion.li>
    </>
  );
});

// Accept all books data
const BooksNew = React.memo((props) => {
  const booksData = props.data ?? '';
  // console.log(booksData, '読み込み前のデータ確認');

  return (
    <>
      {booksData.length === 0 && <FirstAdd />}
      <motion.ul
        variants={moveParent}
        initial="hidden"
        animate="visible"
        style={{ margin: 0, padding: 0 }}
      >
        <InfiniteScroll
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            listStyle: 'none',
          }}
          dataLength={booksData.length}
          next={() => props.nextFunc()}
          // hasMoreはデフォルトはfalseのはず、、なのでアカウント画面でnextFuncを渡さなくてもエラーにならない
          hasMore={props.hasMore}
          loader={<LoadingCircle />}
          // endMessage={
          //   <h1 style={{ textAlign: 'center' }}>
          //     <b>You have seen it all</b>
          //   </h1>
          // }
        >
          {booksData &&
            booksData.map((v) => <BooksChild key={v.title} props={v} />)}
        </InfiniteScroll>
      </motion.ul>
    </>
  );
});

export { BooksNew };
