// urlのisbnからデータ取得、描画する
import React, { useContext, useState } from 'react';
import { TempGraph } from '../templates/TempGraph';
import { switchStateVar } from '../atoms/SwitchState';
import { useReactiveVar } from '@apollo/client';
import { AuthContext } from '../../containers/auth';

const Graph = () => {
  const isOwn = useReactiveVar(switchStateVar);
  const { authState } = useContext(AuthContext);

  // // window幅の変更を検知
  // const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  // let intFrameWidth = window.innerWidth;
  // console.log(windowWidth);
  // window.onresize = () => {
  //   setWindowWidth(window.innerWidth);
  // };
  // useEffect(() => {
  //   };
  // }, []);

  return (
    <>
      {authState.status === 'in' && isOwn ? (
        <TempGraph isOwn={isOwn} />
      ) : (
        <TempGraph />
      )}
    </>
  );
};

export { Graph };
