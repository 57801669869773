// postgresSQLから本情報を取得
import { useQuery, gql } from '@apollo/client';

// postgresSQLの本情報を取得

const GET_LATEST_MEMOS = gql`
  query MyQuery {
    memos(order_by: { created_at: desc }, limit: 1) {
      memo_title
      memo_text
      isbn
      memo_id
      memos_to_books {
        title
      }
    }
  }
`;

const useGetLatestMemos = (isbn) => {
  const { loading, error, data } = useQuery(GET_LATEST_MEMOS, {
    // notifyOnNetworkStatusChange: true,
    // variables: { isbn: isbn || '%' },
    // fetchPolicy: 'no-cache',
    // pollInterval: 5000,
  });

  // if (networkStatus === NetworkStatus.refetch) refetch();
  if (loading) return null;
  if (error) return `Error! ${error}`;
  return data;
};

export { GET_LATEST_MEMOS, useGetLatestMemos };
