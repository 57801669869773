// postgresSQLから本情報を取得
import { useQuery, gql, NetworkStatus } from '@apollo/client';

// postgresSQLの本情報を取得

const GET_MEMOS = gql`
  query MyQuery(
    $isbn: String
    $user_id: String
    $limit_number: Int
    $offset_number: Int
    $display_name: String
  ) {
    memos(
      where: {
        isbn: { _eq: $isbn }
        user_id: { _eq: $user_id }
        memos_to_users: { display_name: { _eq: $display_name } }
      }
      order_by: { created_at: desc }
      limit: $limit_number
      offset: $offset_number
    ) {
      memo_id
      memo_title
      memo_text
      isbn
      user_id
      updated_at
      memos_to_users {
        display_name
      }
      memos_to_books {
        image_path
      }
    }
  }
`;

const useGetMemos = (isbn) => {
  const { loading, error, data } = useQuery(GET_MEMOS, {
    // notifyOnNetworkStatusChange: true,
    variables: { isbn: isbn || '%' },
    // fetchPolicy: 'no-cache',
    // pollInterval: 5000
  });

  // if (networkStatus === NetworkStatus.refetch) refetch();
  if (loading) return null;
  if (error) return `Error! ${error}`;
  return data;
};

export { GET_MEMOS, useGetMemos };
