import { IoHelpSharp } from 'react-icons/io5';
import { IconContext } from 'react-icons';
import Fab from '@mui/material/Fab';

// もらったpropsをonClickとして実行する
const HelpIcon = (props) => {
  return (
    <Fab
      color="secondary"
      position="absolute"
      top={40}
      left="40%"
      aria-label="add"
      onClick={props.click}
    >
      <IconContext.Provider value={{ size: '2em' }}>
        <IoHelpSharp />
      </IconContext.Provider>
    </Fab>
  );
};

export { HelpIcon };
