// バーコードスキャナー、クリックするとスキャン画面が開く
// バーコードスキャンし、ISBN取得したら子モーダルを出す
import React, { useState } from 'react';
import { Scanner } from '../../containers/Scanner';
import { Box, Button, Dialog, DialogActions } from '@mui/material';
import { ScanIcon } from '../atoms/ScanIcon';
import { getInfo } from '../../containers/getDataFromOpendb';
import { AddBookDetailModal } from './AddBook_DetailModal';
import { useModalBefore } from '../../containers/ModalBeforeRegister';
import { useAddBookData } from '../../containers/AddBook';
import { useCheckBooks } from '../../containers/CheckBooks';
import { useAddOwner } from '../../containers/AddOwner';
// import { useCheckOwner } from '../../containers/CheckOwner';
import { useNavigate } from 'react-router-dom';

// propsは親のモーダル(AddBook_Before)を閉じる関数
// スキャン後のisbnがscanCodeに格納される(_onDetectedでモーダルが閉じる)
const AddBookScanner = (props) => {
  const [scanCode, setScanCode] = useState(null);
  const [modal, setModal] = useState(false);
  // openDBからのデータ格納
  const [bookData, setBookData] = useState('');

  // let res2 = CheckBooks(scanCode === '' ? null : scanCode);

  const _toggle = () => {
    setModal(!modal);
  };

  /** After scanning, setState(isbn) and close modal.
   *
   * @param {string} result isbn
   */
  const _onDetected = (result) => {
    setModal(false);
    // console.log(result, 'scan結果');
    setScanCode(result && result.codeResult.code);
  };

  // useでまとめた関数を取得、子のモーダルを閉じる関数とデータ登録関数
  const { open, handleClickOpen, handleClose, ownerId } = useModalBefore();

  // 本を登録する関数
  const addBookData = useAddBookData();

  // 本を所持済みかのチェック、子のコンポーネントでの表示切り替えに使用
  // const [bookExist, setBookExist] = useState(false);
  // const ownerArr = useCheckOwner(ownerId, scanCode);

  // オーナーテーブルを追加する関数
  const addOwner = useAddOwner();

  const navigate = useNavigate();

  // graphQLで取得した配列を格納、handleSubmit内でlengthに応じて取得を分岐
  let gqlRes = useCheckBooks(scanCode);

  /** Hasuraのbookテーブルからデータ取得した際にstateを更新する関数
   *
   */
  const stateChange = () => {
    console.log(gqlRes.books[0], 'stateChange内');
    setBookData(gqlRes.books[0]);
  };

  /** ISBN取得後(scanCodeが''以外の時、)子のモーダルに本の情報を渡す \
   * ISBNコード入力後にopenDBにアクセスし、本の情報を取得 \
   * getInfoはawait,asyncを使用しているため、promise型が返ってくる。
   */
  const handleSubmit = async () => {
    if (scanCode && gqlRes.books.length === 0) {
      const opendbRes = await getInfo(scanCode);
      if (opendbRes === 'failure') {
        alert('次のISBNコードが見つかりませんでした:', scanCode);
      } else {
        setBookData(opendbRes);
        handleClickOpen();
        console.log('openDB経由');
        bookData !== '' && addBookData(bookData);
      }
    } else if (scanCode) {
      // lengthが0以外(postgreSQLにデータが存在する時)はHasuraにアクセスして取得する
      stateChange();
      console.log('GraphQL経由');
      bookData && console.log(bookData);
      handleClickOpen();
    }

    setScanCode(null);
  };

  // スキャン後にISBN取得できたらopenDBへのアクセス・判定を行い、モーダルを出す
  if (scanCode && gqlRes) {
    // console.log(scanCode, 'scancodeとれてるかチェック');
    handleSubmit();
  }

  // 子のモーダルを閉じる & graphQLを投げる & 親のモーダル(AddBook_Before)を閉じる関数
  // ※本コンポーネント(InputIsbnFiledは親モーダルの一部)
  const handleCloseAndRegist = (v) => {
    handleClose();
    // addBookData(ownerId, v);
    addOwner(ownerId, v);
    setTimeout(() => {
      props.onclose();
    }, 300);
    navigate(`../../book/${v.isbn}`);
  };

  return (
    <Box>
      <Button onClick={_toggle} variant="outlined">
        <ScanIcon />
      </Button>

      {bookData ? (
        <AddBookDetailModal
          bookData={bookData}
          open={open}
          onclose={handleClose}
          // bookExist={
          //   (ownerArr.owner || false).length && ownerArr.owner.length === 1
          // }
          // regist関数は引数付きで渡してるのでアロー関数で渡している、子のpropのデータがvに入り実行される
          regist={(v) => handleCloseAndRegist(v)}
        />
      ) : null}
      <Dialog
        open={modal}
        onClose={_toggle}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <DialogActions>
          <Scanner handleScan={_onDetected} />
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export { AddBookScanner };
