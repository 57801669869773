import { useState, useContext } from 'react';
import { AuthContext } from '../containers/auth';

/** 登録確認画面(子のコンポーネント)の開閉と認証状態を格納
 *
 */
const useModalBefore = () => {
  const [open, setOpen] = useState(false);
  const { authState } = useContext(AuthContext);
  const ownerId = authState.user.uid;

  const handleClickOpen = () => {
    setOpen(true);
  };
  // 子に渡すモーダルを閉じる関数
  const handleClose = () => {
    setOpen(false);
  };
  return {
    open,
    handleClickOpen,
    handleClose,
    ownerId,
  };
};

export { useModalBefore };
