import { BiBarcodeReader } from 'react-icons/bi';
import { IconContext } from 'react-icons';
import { Typography } from '@mui/material';

const ScanIcon = () => {
  return (
    <IconContext.Provider value={{ size: '3em' }}>
      <BiBarcodeReader />
      <Typography variant="body2" color="text.secondary">
        ISBNコードをスキャン
      </Typography>
    </IconContext.Provider>
  );
};

export { ScanIcon };
