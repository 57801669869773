// urlのisbnからデータ取得、描画する
import React, { useContext, useState, useRef, useEffect } from 'react';
import ForceGraph3D from 'react-force-graph-3d';
import SpriteText from 'three-spritetext';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetGraphData } from '../../containers/GetGraphData';
import { NotFound } from '../pages/NotFound';
import { useTracking } from '../../containers/useTracking';
import { Helmet } from 'react-helmet';
import { AuthContext } from '../../containers/auth';
import LoadingCircle from '../atoms/LoadingCircle';
import { FirstAdd } from '../organisms/FirstAdd';

const TempGraphChild = React.memo((v) => {
  // nodeクリック時の遷移処理
  useTracking(process.env.REACT_APP_GA_MEASUREMENT_ID);
  const navigate = useNavigate();
  const navigateBook = (node) => {
    switch (node.class) {
      case 'book':
        navigate(`../../book/${node.id}`);
        break;
      case 'note':
        navigate(`../../book/${node.isbn}#${node.id}`);
        break;
      case 'user':
        navigate(`../../account/${node.id}`);
        break;
      default:
        break;
    }
  };

  // nodesの本データだけがisbnを持っている
  const depData = v && v.data;
  // v && console.log(depData, 'もとでーたのかくにん！');

  // const distance = 900;
  // const fgRef = useRef();

  // useEffect(() => {
  //   // fgRef.current && fgRef.current.cameraPosition({ z: distance });

  //   // camera orbit
  //   let angle = 0;
  //   setInterval(() => {
  //     fgRef.current.cameraPosition({
  //       x: 1000 * Math.sin(angle),
  //       z: 1000 * Math.cos(angle),
  //     });
  //     angle += Math.PI / 300;
  //   }, 20);
  // }, [v]);

  return (
    <ForceGraph3D
      // ref={fgRef}
      graphData={depData}
      backgroundColor="#000"
      linkWidth={0.3}
      minZoom="2"
      controlType="trackball"
      numDimensions="3"
      forceEngine="d3"
      dagMode="zout"
      dagLevelDistance={30}
      nodeId="id"
      nodeAutoColorBy="uid"
      linkDirectionalParticles={2}
      linkDirectionalParticleWidth={1}
      linkOpacity={0.4}
      onNodeClick={(node) => node && navigateBook(node)}
      // onNodeHover={(node) => {}}
      nodeThreeObject={(node) => {
        const sprite = new SpriteText(node.title);
        sprite.color = node.class === 'user' ? '#ec407a' : node.color;
        // sprite.textHeight = 10;
        return sprite;
      }}
    />
  );
});

const TempGraph = (props) => {
  const { authState } = useContext(AuthContext);
  // let { slug } = useParams();
  // isbnをからグラフ描画データを作成
  // let props = slug;
  const graphData = useGetGraphData(
    props.isOwn && authState && authState.user.uid
  );

  // console.log(graphData, 'graphDataそのもの');
  // console.log(graphData && graphData.memos, 'graphDataの確認');

  // user、book、noteそれぞれ抽出してnodeを作成
  // それぞれのnodeからlinkを作成
  // node,linkそれぞれを合成し、最後にオブジェクトとして合成する

  // user_idごとに紐づくisbnを抽出
  const userNode =
    graphData &&
    graphData.memos.reduce((res, cur) => {
      const element = res.find(
        (p) => p.id === cur.memos_to_users.user_name && p.isbn === cur.isbn
      );
      if (!element) {
        res.push({
          id: cur.memos_to_users.user_name,
          isbn: cur.isbn,
          title: cur.memos_to_users.display_name,
          class: 'user',
          count: 10,
        });
      }
      return res;
    }, []);

  // console.log(userNode, 'userノードの確認');

  // isbnごとに紐づくメモを抽出
  const bookNode =
    graphData &&
    graphData.memos.reduce((res, cur) => {
      const element = res.find((p) => p.id === cur.isbn);
      if (!element) {
        res.push({
          id: cur.isbn,
          title: cur.memos_to_books.title,
          class: 'book',
          count: 1,
        });
      }
      return res;
    }, []);

  // console.log(graphData, '整形前ノードの確認');
  // console.log(bookNode, 'bookノードの確認');

  const noteNode =
    graphData &&
    graphData.memos.reduce((res, cur) => {
      res.push({
        id: cur.memo_id,
        isbn: cur.isbn,
        title: cur.memo_title,
        uid: cur.user_id,
        created_at: cur.created_at,
        class: 'note',
        count: 6,
      });
      return res;
    }, []);

  // console.log(noteNode, 'noteノードの確認');

  // userとbookからlinkを作成
  const links1 = [];
  userNode &&
    userNode.forEach((v) => {
      bookNode.forEach((v2) => {
        v2.id === v.isbn &&
          links1.push({
            target: v2.id,
            source: v.id,
          });
      });
    });

  // bookとnoteのnodeからlinkを作成
  const links2 = [];
  bookNode &&
    bookNode.forEach((v) => {
      noteNode.forEach((v2) => {
        v2.isbn === v.id &&
          links2.push({
            target: v2.id,
            source: v2.isbn,
          });
      });
    });

  // userNodeの一意化
  const resUserNode =
    userNode &&
    userNode.reduce((res, cur) => {
      const element = res.find((p) => p.id === cur.id);
      if (element) {
        element.count++;
      } else {
        res.push({
          id: cur.id,
          title: cur.title,
          class: cur.class,
          count: 1,
        });
      }
      return res;
    }, []);

  const source2 =
    resUserNode &&
    bookNode &&
    noteNode &&
    bookNode.concat(noteNode).concat(resUserNode);

  // console.log(source2, '合成の確認');
  // bookNodeからidを取得、noteNodeのisbnで一致したらidを取得、bookNodeとnoteNodeでlinkを作成

  const allLinks = links2.concat(links1);

  let dotsData = {};
  dotsData.links = allLinks;
  dotsData.nodes = source2;

  const result =
    graphData && graphData.memos.length !== 0 ? (
      <>
        <Helmet>
          <title>NoteGraph</title>
          <style>{'body { background-color: #000; }'}</style>
        </Helmet>
        {!dotsData ? <LoadingCircle /> : <TempGraphChild data={dotsData} />}
      </>
    ) : (
      // <NotFound />
      <FirstAdd />
    );

  return graphData && result;
};

export { TempGraph };
