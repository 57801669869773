import ReactTimeAgo from 'react-time-ago';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
TimeAgo.addDefaultLocale(en);

const PastTime = (prop) => {
  const trgDate = new Date(prop.time);
  const trgLocaleDate = trgDate.setMinutes(
    trgDate.getMinutes() - trgDate.getTimezoneOffset()
  );
  return <ReactTimeAgo date={trgLocaleDate} />;
};

export { PastTime };
