// postgresSQLから自分のプロフィール情報を取得
import { useQuery, gql, NetworkStatus } from '@apollo/client';

const GET_OWN_USER = gql`
  query GetOwnUser($user_id: String) {
    users(where: { user_id: { _eq: $user_id } }) {
      display_image
      display_name
      text
      user_name
    }
  }
`;

/** To get own user info form the user table
 *
 * @param {string} uuid
 * @returns data
 */
const GetOwnUser = (uuid) => {
  const { loading, error, data, refetch, networkStatus } = useQuery(
    GET_OWN_USER,
    {
      notifyOnNetworkStatusChange: true,
      variables: { user_id: uuid || '%' },
    }
  );

  if (networkStatus === NetworkStatus.refetch) refetch();
  if (loading) return 'loading';
  if (error) return `Error! ${error}`;
  return data;
};

export { GET_OWN_USER, GetOwnUser };
