// 本登録前にDBにデータがないかチェック
import { useQuery, gql, NetworkStatus } from '@apollo/client';
import { useCallback } from 'react';
const CHECK_BOOKS = gql`
  query checkBooks($limit_number: Int, $isbn_code: String) {
    books(
      limit: $limit_number
      order_by: { updated_at: desc }
      where: { isbn: { _eq: $isbn_code } }
    ) {
      title
      isbn
      author
      image_path
      pages
      price
      publish_date
      publisher
      text
    }
  }
`;

/** Check existence of book in the book table.
 *
 * @param {*} isbn
 */
const useCheckBooks = (isbn) => {
  const { loading, error, data, refetch, networkStatus } = useQuery(
    CHECK_BOOKS,
    {
      notifyOnNetworkStatusChange: true,
      variables: { limit_number: 1, isbn_code: isbn || '%' },
    }
  );

  if (networkStatus === NetworkStatus.refetch) refetch();
  if (loading) return null;
  if (error) return `Error! ${error}`;
  // console.log(isbn);
  return data;
};

export { CHECK_BOOKS, useCheckBooks };
