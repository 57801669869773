import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Grid, Typography, Box } from '@mui/material';
import { Helmet } from 'react-helmet';
import { useTracking } from '../../containers/useTracking';
import { Link } from 'react-router-dom';

const Wrapper = styled.div`
  margin: 20px 20px 0;
  // width: 100%;
  justify-content: center;
  display: flex;
  text-align:center;
  // flex: wrap;
  maxWidth="700px"

  // maxwidth: 600px;
`;

// 初回レンダリング時にページトップにスクロールさせる

const Tos = React.memo(() => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useTracking(process.env.REACT_APP_GA_MEASUREMENT_ID);
  return (
    <Wrapper>
      <Helmet>
        <title>利用規約</title>
      </Helmet>
      <Box
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        textAlign="left"
        // width="100%"
      >
        <Typography
          variant="h5"
          gutterBottom
          style={{ fontWeight: 600, marginTop: 0 }}
        >
          利用規約
        </Typography>
        <br />
        <Typography variant="body1" paragraph>
          この利用規約（以下、「本規約」といいます。）は、NoteQualia運営事務局（以下、「当事務局」といいます。）がこのウェブサイト上で提供するサービス（以下、「本サービス」といいます。）の利用条件を定めるものです。
          <br />
          登録ユーザーの皆さま（以下、「ユーザー」といいます。）には、本規約に従って、本サービスをご利用いただきます。
        </Typography>
        <Typography variant="subtitle1" paragraph>
          第1条（適用）
        </Typography>
        <Typography variant="body2" paragraph>
          1.本規約は,ユーザーと当事務局との間の本サービスの利用に関わる一切の関係に適用されるものとします。
          <br />
          2.当事務局は本サービスに関し、本規約のほか、ご利用にあたってのルール等、各種の定め（以下、「個別規定」といいます。）をすることがあります。これら個別規定はその名称のいかんに関わらず、本規約の一部を構成するものとします。
          <br />
          3.本規約の規定が前条の個別規定の規定と矛盾する場合には、個別規定において特段の定めなき限り、個別規定の規定が優先されるものとします。
        </Typography>
        <Typography variant="subtitle1" paragraph>
          第2条（利用登録）
        </Typography>
        <Typography variant="body2" paragraph>
          1.本サービスにおいては、登録希望者が本規約に同意の上、当事務局の定める方法によって利用登録を申請し、当事務局がこれを承認することによって、利用登録が完了するものとします。
          <br />
          2.当事務局は、利用登録の申請者に以下の事由があると判断した場合、利用登録の申請を承認しないことがあり、その理由については一切の開示義務を負わないものとします。
          <br />
          　1.利用登録の申請に際して虚偽の事項を届け出た場合
          <br />
          　2.本規約に違反したことがある者からの申請である場合
          <br />
          　3.その他、当事務局が利用登録を相当でないと判断した場合
        </Typography>
        <Typography variant="subtitle1" paragraph>
          第3条（ユーザーIDおよびパスワードの管理）
        </Typography>
        <Typography variant="body2" paragraph>
          1.ユーザーは、自己の責任において、本サービスのユーザーIDおよびパスワードを適切に管理するものとします。
          <br />
          2.ユーザーは、いかなる場合にも、ユーザーIDおよびパスワードを第三者に譲渡または貸与し、もしくは第三者と共用することはできません。当事務局は、ユーザーIDとパスワードの組み合わせが登録情報と一致してログインされた場合には、そのユーザーIDを登録しているユーザー自身による利用とみなします。
          <br />
          3.ユーザーID及びパスワードが第三者によって使用されたことによって生じた損害は、当事務局に故意又は重大な過失がある場合を除き、当事務局は一切の責任を負わないものとします。
        </Typography>
        <Typography variant="subtitle1" paragraph>
          第4条（禁止事項）
        </Typography>
        <Typography variant="body2" paragraph>
          ユーザーは、本サービスの利用にあたり、以下の行為をしてはなりません。
          <br />
          1.法令または公序良俗に違反する行為
          <br />
          2.犯罪行為に関連する行為
          <br />
          3.本サービスの内容等、本サービスに含まれる著作権、商標権ほか知的財産権を侵害する行為
          <br />
          4.当事務局、ほかのユーザー、またはその他第三者のサーバーまたはネットワークの機能を破壊したり、妨害したりする行為
          <br />
          5.本サービスによって得られた情報を商業的に利用する行為
          <br />
          6.当事務局のサービスの運営を妨害するおそれのある行為
          <br />
          7.不正アクセスをし、またはこれを試みる行為
          <br />
          8.他のユーザーに関する個人情報等を収集または蓄積する行為
          <br />
          9.不正な目的を持って本サービスを利用する行為
          <br />
          10.本サービスの他のユーザーまたはその他の第三者に不利益、損害、不快感を与える行為
          <br />
          11.他のユーザーに成りすます行為
          <br />
          12.当事務局が許諾しない本サービス上での宣伝、広告、勧誘、または営業行為
          <br />
          13.面識のない異性との出会いを目的とした行為
          <br />
          14.当事務局のサービスに関連して、反社会的勢力に対して直接または間接に利益を供与する行為
          <br />
          15.その他、当事務局が不適切と判断する行為
        </Typography>
        <Typography variant="subtitle1" paragraph>
          第5条（本サービスの提供の停止等）
        </Typography>

        <Typography variant="body2" paragraph>
          1.当事務局は、以下のいずれかの事由があると判断した場合、ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。
          <br />
          　1.本サービスにかかるコンピュータシステムの保守点検または更新を行う場合
          <br />
          　2.地震、落雷、火災、停電または天災などの不可抗力により、本サービスの提供が困難となった場合
          <br />
          　3.コンピュータまたは通信回線等が事故により停止した場合
          <br />
          　4.その他、当事務局が本サービスの提供が困難と判断した場合
          <br />
          2.当事務局は、本サービスの提供の停止または中断により、ユーザーまたは第三者が被ったいかなる不利益または損害についても、一切の責任を負わないものとします。
        </Typography>
        <Typography variant="subtitle1" paragraph>
          第6条（利用制限および登録抹消）
        </Typography>
        <Typography variant="body2" paragraph>
          1.当事務局は、ユーザーが以下のいずれかに該当する場合には、事前の通知なく、ユーザーに対して、本サービスの全部もしくは一部の利用を制限し、またはユーザーとしての登録を抹消することができるものとします。
          <br />
          　1.本規約のいずれかの条項に違反した場合
          <br />
          　2.登録事項に虚偽の事実があることが判明した場合
          <br />
          　3.料金等の支払債務の不履行があった場合
          <br />
          　4.当事務局からの連絡に対し、一定期間返答がない場合
          <br />
          　5.本サービスについて、最終の利用から一定期間利用がない場合
          <br />
          　6.その他、当事務局が本サービスの利用を適当でないと判断した場合
          <br />
          2.当事務局は、本条に基づき当事務局が行った行為によりユーザーに生じた損害について、一切の責任を負いません。
        </Typography>
        <Typography variant="subtitle1" paragraph>
          第7条（退会）
        </Typography>
        <Typography variant="body2" paragraph>
          ユーザーは、当事務局の定める退会手続により、本サービスから退会できるものとします。
          <br />
          退会手続きはお問い合わせよりご連絡ください。
        </Typography>
        <Typography variant="subtitle1" paragraph>
          第8条（保証の否認および免責事項）
        </Typography>
        <Typography variant="body2" paragraph>
          1.当事務局は、本サービスに事実上または法律上の瑕疵（安全性、信頼性、正確性、完全性、有効性、特定の目的への適合性、セキュリティなどに関する欠陥、エラーやバグ、権利侵害などを含みます。）がないことを明示的にも黙示的にも保証しておりません。
          <br />
          2.当事務局は、本サービスに起因してユーザーに生じたあらゆる損害について一切の責任を負いません。ただし、本サービスに関する当事務局とユーザーとの間の契約（本規約を含みます。）が消費者契約法に定める消費者契約となる場合、この免責規定は適用されません。
          <br />
          3.前項ただし書に定める場合であっても、当事務局は、当事務局の過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害のうち特別な事情から生じた損害（当事務局またはユーザーが損害発生につき予見し、または予見し得た場合を含みます。）について一切の責任を負いません。また、当事務局の過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害の賠償は、ユーザーから当該損害が発生した月に受領した利用料の額を上限とします。
          <br />
          4.当事務局は、本サービスに関して、ユーザーと他のユーザーまたは第三者との間において生じた取引、連絡または紛争等について一切責任を負いません。
        </Typography>
        <Typography variant="subtitle1" paragraph>
          第9条（サービス内容の変更等）
        </Typography>
        <Typography variant="body2" paragraph>
          当事務局は、ユーザーに通知することなく、本サービスの内容を変更しまたは本サービスの提供を中止することができるものとし、これによってユーザーに生じた損害について一切の責任を負いません。
        </Typography>
        <Typography variant="subtitle1" paragraph>
          第10条（利用規約の変更）
        </Typography>
        <Typography variant="body2" paragraph>
          当事務局は、必要と判断した場合には、ユーザーに通知することなくいつでも本規約を変更することができるものとします。なお、本規約の変更後、本サービスの利用を開始した場合には、当該ユーザーは変更後の規約に同意したものとみなします。
        </Typography>
        <Typography variant="subtitle1" paragraph>
          第11条（個人情報の取扱い）
        </Typography>
        <Typography variant="body2" paragraph>
          当事務局は、本サービスの利用によって取得する個人情報については、当事務局「プライバシーポリシー」に従い適切に取り扱うものとします。
        </Typography>
        <Typography variant="subtitle1" paragraph>
          第12条（通知または連絡）
        </Typography>
        <Typography variant="body2" paragraph>
          ユーザーと当事務局との間の通知または連絡は、当事務局の定める方法によって行うものとします。当事務局は,ユーザーから,当事務局が別途定める方式に従った変更届け出がない限り,現在登録されている連絡先が有効なものとみなして当該連絡先へ通知または連絡を行い,これらは,発信時にユーザーへ到達したものとみなします。
        </Typography>
        <Typography variant="subtitle1" paragraph>
          第13条（権利義務の譲渡の禁止）
        </Typography>
        <Typography variant="body2" paragraph>
          ユーザーは、当事務局の書面による事前の承諾なく、利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し、または担保に供することはできません。
        </Typography>
        <Typography variant="subtitle1" paragraph>
          第14条（準拠法・裁判管轄）
        </Typography>
        <Typography variant="body2" paragraph>
          1.本規約の解釈にあたっては、日本法を準拠法とします。
          <br />
          2.本サービスに関して紛争が生じた場合には、当事務局の本店所在地を管轄する裁判所を専属的合意管轄とします。
        </Typography>

        <Typography variant="body2" paragraph>
          以上
        </Typography>
        <Link to={'/policy'} style={{ textDecoration: 'none', color: '#000' }}>
          <Typography
            variant="h6"
            gutterBottom
            style={{ fontWeight: 600, marginTop: 20 }}
          >
            プライバシーポリシー
          </Typography>
        </Link>
        <Typography
          variant="h6"
          gutterBottom
          style={{ fontWeight: 600, marginTop: 10 }}
        >
          <a
            style={{ textDecoration: 'none', color: '#000' }}
            href="https://docs.google.com/forms/d/e/1FAIpQLSfwkBUXUoZN_3eP8yrPR9dp2-2gYZBAPXw0THXP1hwi46TggA/viewform"
            target="_blank"
            rel="noreferrer"
          >
            お問い合わせ
          </a>
        </Typography>
        {/* <Typography>
          <a
            style={{ textDecoration: 'none', color: '#000' }}
            href="https://docs.google.com/forms/d/e/1FAIpQLSfwkBUXUoZN_3eP8yrPR9dp2-2gYZBAPXw0THXP1hwi46TggA/viewform"
          >
            Google Form

        </Typography> */}
      </Box>
    </Wrapper>
  );
});

export { Tos };
