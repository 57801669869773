// openDBにISBNを渡してデータを取得
import axios from 'axios';

const getInfo = async (v) => {
  let bookInfo = '';
  try {
    const bookJson = await axios.get(`https://api.openbd.jp/v1/get?isbn=${v}`);

    const bookData = {
      title: bookJson.data[0].summary.title,
      price:
        bookJson.data[0].onix.ProductSupply.SupplyDetail.Price[0].PriceAmount,
      pages: (
        ((bookJson.data[0].onix.DescriptiveDetail || '').Extent || '')[0] || ''
      ).ExtentValue,
      isbn: v,
      image_path: bookJson.data[0].summary.cover || '',
      subject_code:
        bookJson.data[0].onix.DescriptiveDetail.Subject[0].SubjectCode,
      author: bookJson.data[0].summary.author,
      publisher: bookJson.data[0].summary.publisher,
      publish_date:
        bookJson.data[0].summary.pubdate.slice(0, 4) +
        '-' +
        bookJson.data[0].summary.pubdate.slice(4, 6) +
        '-' +
        bookJson.data[0].summary.pubdate.slice(6, 8),
      update_date: Date.now(),
      text: (
        (bookJson.data[0].onix.CollateralDetail.TextContent || '')[0] || ''
      ).Text,
    };
    bookInfo = bookData;
    // console.log(bookData);
  } catch (err) {
    console.error(err);
    return 'failure';
  }
  return await bookInfo;
};

export { getInfo };
