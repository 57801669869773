import React from 'react';
import { Box, CircularProgress } from '@mui/material';

const LoadingCircle = () => {
  return (
    <Box style={{ margin: '50px', display: 'flex', justifyContent: 'center' }}>
      <CircularProgress size={50} />
    </Box>
  );
};

export default LoadingCircle;
