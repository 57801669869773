import React, { useEffect, useState, useContext } from 'react';
import { signIn, signOut } from '../../containers/auth';
import { AuthContext } from '../../containers/auth';
import { GetOwnUser } from '../../containers/GetOwnUser';

import {
  AppBar,
  Button,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
  Stack,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { Link, useNavigate } from 'react-router-dom';
import { UseGetIdentifier } from '../../containers/auth';
import { About } from '../pages/About';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';

// userテーブルからuser_nameをもらうコンポーネント
const useGetUid = (authState) => {
  // console.log(authState, 'graphQLを投げる前');
  const ownUser = GetOwnUser(authState.user && authState.user.uid);
  // console.log(ownUser, authState.user && authState.user.uid);
  return ownUser !== 'loading' ? ownUser : null;
};
const useStyles = makeStyles(() => ({
  mobileLink: {
    width: '100%',
    textDecoration: 'none',
    color: '#000',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  mobileDialog: {
    // position: 'absolute',
    top: 52,
  },
  title: {
    flexGrow: 1,
    color: '#fff',
    textDecoration: 'none',
  },
}));

// ログイン後に表示
const MenuArea = React.memo((props) => {
  // console.log(props, 'uketottaato');
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const logOut = () => {
    setAnchorEl(null);
    signOut();
  };
  return (
    <>
      <Button variant="outlined" onClick={handleClick} color="secondary">
        Menu
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>
          {/* <Link to="account/sss">My account</Link> */}
          <Link
            to={`account/${props && props.props}`}
            style={{ textDecoration: 'none', color: '#000' }}
          >
            My Page
          </Link>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          {/* <Link to="account/sss">My account</Link> */}
          <Link to={`about`} style={{ textDecoration: 'none', color: '#000' }}>
            About
          </Link>
        </MenuItem>
        <MenuItem onClick={logOut}>Logout</MenuItem>
      </Menu>
    </>
  );
});

const MenuAreaMobile = React.memo((props) => {
  // console.log(props, 'mobileのprop');
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const logOut = () => {
    signOut();
  };
  const classes = useStyles();

  return (
    <>
      <IconButton variant="outlined" onClick={handleClickOpen}>
        <MenuIcon color="secondary" />
      </IconButton>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        transitionDuration={0}
        className={classes.mobileDialog}
      >
        <AppBar color="primary">
          <Toolbar>
            <Typography
              variant="h6"
              className={classes.title}
              to="/"
              component={Link}
              onClick={handleClose}
            >
              NoteQualia
            </Typography>
            <IconButton
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <List>
          <ListItem button onClick={handleClose}>
            <Link
              to={`account/${props && props.props}`}
              className={classes.mobileLink}
            >
              <ListItemText
                primary="My Page"
                primaryTypographyProps={{ variant: 'h6' }}
              />
            </Link>
          </ListItem>
          <Divider />
          <ListItem button onClick={handleClose}>
            <Link to={`about`} className={classes.mobileLink}>
              <ListItemText
                primary="About"
                primaryTypographyProps={{ variant: 'h6' }}
              />
            </Link>
          </ListItem>
          <Divider />
          <ListItem button onClick={handleClose}>
            <Link to={`book`} className={classes.mobileLink}>
              <ListItemText
                primary="Books"
                primaryTypographyProps={{ variant: 'h6' }}
              />
            </Link>
          </ListItem>
          <Divider />
          <ListItem button onClick={handleClose}>
            <Link to={`notes`} className={classes.mobileLink}>
              <ListItemText
                primary="Notes"
                primaryTypographyProps={{ variant: 'h6' }}
              />
            </Link>
          </ListItem>
          <Divider />
          <ListItem button onClick={handleClose}>
            <Link to={`graph`} className={classes.mobileLink}>
              <ListItemText
                primary="Graph"
                primaryTypographyProps={{ variant: 'h6' }}
              />
            </Link>
          </ListItem>
          <Divider />
          <ListItem button onClick={logOut} className={classes.mobileLink}>
            <ListItemText
              primary="Logout"
              primaryTypographyProps={{ variant: 'h6' }}
            />
          </ListItem>
        </List>
      </Dialog>
    </>
  );
});

const Login = () => {
  const { authState } = useContext(AuthContext);
  const [urlState, setUrlState] = useState(null);
  // console.log(authState, 'Loginのauthの確認');
  const mobileStyle = useMediaQuery('(max-width:600px)');
  const twIdentifier = UseGetIdentifier();
  const res = useGetUid(twIdentifier && authState);
  useEffect(() => {
    res && setUrlState(res);
  }, [res]);

  return (
    <div>
      {authState.status === 'in' ? (
        <>
          {!mobileStyle ? (
            <MenuArea
              props={
                urlState &&
                urlState.users &&
                urlState.users[0] &&
                urlState.users[0].user_name
              }
            />
          ) : (
            <MenuAreaMobile
              props={
                urlState &&
                urlState.users &&
                urlState.users[0] &&
                urlState.users[0].user_name
              }
            />
          )}
        </>
      ) : (
        <Button variant="outlined" color="secondary" onClick={() => signIn()}>
          <Typography>Twitterログイン</Typography>
        </Button>
      )}
    </div>
  );
};

export { Login };
