import { gql, useMutation } from '@apollo/client';
import { GET_BOOKS } from './GetBooks';

/** openDBから取得したデータをpostgresSQLに登録
 *
 */
const ADD_OWNER = gql`
  mutation MyMutation($user_id: String, $isbn: String, $having_status: String) {
    insert_owner_one(
      object: { user_id: $user_id, isbn: $isbn, having_status: $having_status }
      on_conflict: { constraint: owner_pkey, update_columns: [] }
    ) {
      user_id
    }
  }
`;

/** Insert owner table
 * @param {string} ownerId - uid
 * @param {string} isbn - isbn
 * @returns insert owner table
 */
const useAddOwner = () => {
  const [addOwner] = useMutation(ADD_OWNER, {
    refetchQueries: [{ query: GET_BOOKS }],
  });
  const addOwnerData = (ownerId, v) => {
    addOwner({
      variables: {
        user_id: ownerId,
        isbn: v.isbn,
        having_status: 'own',
      },
    });
  };
  return addOwnerData;
};

export { ADD_OWNER, useAddOwner };
